import { BrowserRouter, Route, Routes } from "react-router-dom";
import Blogs from "./Components/Blogs/Blogs";
import Careers from "./Components/Careers/Careers";
import Contact from "./Components/Contact/Contact";
import Services from "./Components/Services/Services";
import About from "./Components/About/About";
import Home from "./Components/Home/Home";
import { createContext, useState } from "react";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./Components/TermsAndConditions/TermsAndConditions";
import CreationPosition from "./Components/creationPage/CreationPosition";
import BlogCreation from "./Components/Blogs/BlogsCreation";
import TotalBlogs from "./Components/TotalBlogs/TotalBlogs";
import { ToastContainer } from "react-toastify";
export const dataShare = createContext();

function App() {
  const [index, setIndex] = useState(0);
  const [Scrool, setScrool] = useState({ X: 0, Y: 0 });

  return (
    <dataShare.Provider value={{ index, setIndex, Scrool, setScrool }}>
      <BrowserRouter>
        <ToastContainer position="top-right" autoClose={2000} hideProgressBar newestOnTop closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
        <Routes>
          <Route path="/" element={<Home />} exact />
          <Route path="/WhoWeAre" element={<About />} exact />
          <Route path="/Services" element={<Services />} exact />
          <Route path="/services/:serviceName" element={<Services />} exact />
          <Route path="/Blogs" element={<Blogs />} exact />
          {/* <Route path="/BlogsCreation" element={<BlogCreation />}></Route> */}
          <Route path="/Careers" element={<Careers />} exact />
          <Route path="/Contact" element={<Contact />} exact />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/termsandconditions" element={<TermsAndConditions />} />
          <Route path="/createPosition" element={<CreationPosition />} />
          <Route path="/BlogCreation" element={<TotalBlogs />} />
        </Routes>
      </BrowserRouter>
    </dataShare.Provider>
  );
}

export default App;
