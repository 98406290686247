import React, { useContext, useEffect } from "react";
import card1 from "../../Assets/About/Rectangle 140.png";
import card2 from "../../Assets/About/Rectangle 141.png";
import card3 from "../../Assets/About/Rectangle 142.png";
import section2Card1 from "../../Assets/About/Rectangle 144.png";
import Image from "../../Assets/NavBar/Rectangle 136......png";
import Image12 from "../../Assets/Services/what_are_we_do.svg";
import "./About.css";
import NavBar from "../NavBar/NavBar";
import Aos from "aos";
import "aos/dist/aos.css";
import PreFooter from "../PreFooter/PreFooter";
import person1 from "../../Assets/About/person1.jpg";
import person2 from "../../Assets/About/person2.jpeg";
import person3 from "../../Assets/About/person3.jpeg";
import person4 from "../../Assets/About/person4.jpg";
import Footer from "../Footer/Footer";
import { dataShare } from "../../App";

export default function About() {
  useEffect(() => {
    Aos.init({
      duration: 1200, // Duration of animations
      easing: "ease",
    });
  }, []);
  const { setScrool } = useContext(dataShare);
  useEffect(() => {
    setScrool({ X: 0, Y: 0 });
  }, []);

  return (
    <>
      <NavBar title={"Better services just for you"} image={Image12} />
      <div className="about-page">
        <section className="about-container">
          <div className="circle-box1 carrer-circle1 about-circle1"></div>
          <div className="circle-box1 carrer-circle2 about-circle2"></div>
          <div className="about-section-1">
            <div className="about-section-1-title">
              <h1>Welcome to VSDL</h1>
              <p>
                At VSDL, we pride ourselves on being your trusted partner in
                digital transformation and enterprise solutions. With industry
                expertise, we are committed to providing unmatched enterprise
                solutions tailored precisely to your needs. Specializing in
                Oracle Fusion Cloud, Salesforce, Workday, Data Engineering, Java
                Full Stack, etc.. as your reliable companion in navigating the
                complexities of today’s digital landscape.
              </p>
            </div>
            <div className="about-section-1-cards">
              <div className="about-section-1-cards-box">
                <h3>Our Vision</h3>
                <img src={card1} alt="card-img1" />
                <p>
                  To be the catalyst for transformative technology solutions
                  that drive innovation and success for businesses worldwide.
                </p>
              </div>
              <div className="about-section-1-cards-box">
                <h3>Our Process</h3>
                <img src={card2} alt="card-img1" />
                <p>
                  Strategic, collaborative, and results-driven: We analyze,
                  design, and implement solutions tailored to your unique needs.
                </p>
              </div>
              <div className="about-section-1-cards-box">
                <h3>Our Approach</h3>
                <img src={card3} alt="card-img1" />
                <p>
                  Customer-centric and adaptive: We leverage cutting-edge
                  technology and industry expertise to deliver impactful and
                  scalable solutions.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="about-section-2">
          <div className="about-section-2-title">
            <h1>A team of tech experts</h1>
          </div>
          <div className="about-section-2-description">
            {/* <div className="about-section-2-cards"> */}
            <div className="about-section-2-cards-box">
              <img src={person1} alt="section-2-img" />
              {/* </div> */}
            </div>
            <div className="about-section-2-description-content1">
              <p>
                Your go-to team of tech experts, solving problems and creating
                solutions
              </p>
              <p>
                Experienced, lean and adaptive tech team led by our in-house
                consulting professionals with years of experience in
                Development, Optimization, and Managed Services. With knowledge
                in modern operations practices and deep expertise in world-class
                engineering
              </p>
            </div>
            <div className="about-section-2-description-content2"></div>
          </div>
          {/* <div className="about-section-2-cards">
            <div className="about-section-2-cards-box">
              <img src={person1} alt="section-2-img" />
              <h4>Atharva </h4>
              <p>Founder / Director</p>
            </div>
            <div className="about-section-2-cards-box">
              <img src={person2} alt="section-2-img" />
              <h4>Vijay </h4>
              <p>Founder / Director</p>
            </div>
            <div className="about-section-2-cards-box">
              <img src={person3} alt="section-2-img" />
              <h4>Vikram </h4>
              <p>Founder / Director</p>
            </div>
            <div className="about-section-2-cards-box">
              <img src={person4} alt="section-2-img" />
              <h4>Chandu</h4>
              <p>Founder / Director</p>
            </div>
          </div> */}
        </section>

        <section className="about-section3-title">
          <div>
            <h1>
              Achieve faster responses, better experiences, greater insights.
            </h1>
          </div>
        </section>

        <section className="about-section4">
          <PreFooter />
        </section>
        <Footer />
      </div>
    </>
  );
}
