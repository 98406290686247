import React, { useContext, useEffect } from "react";
import "./Contact.css";
import NavBar from "../NavBar/NavBar";
import ContactIMG from "../../Assets/NavBar/ContactNav.jpg";
import call from "../../Assets/Blogs/call.svg";
import chat from "../../Assets/Blogs/chat.svg";
import location from "../../Assets/Blogs/location.svg";
import { BsChatRightDots } from "react-icons/bs";
import { RiUserLocationLine } from "react-icons/ri";
import { TbPhoneCall } from "react-icons/tb";
import Footer from "../Footer/Footer";
import { dataShare } from "../../App";
import emailjs from "@emailjs/browser";
import Swal from "sweetalert2";
// import "sweetalert2/src/sweetalert2.scss";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Contact() {
  const { setScrool } = useContext(dataShare);
  useEffect(() => {
    setScrool({ X: 0, Y: 0 });
  }, []);

  const handleEmail = () => {
    window.location.href = "mailto:hi@vsdltechnologies.com";
  };
  const hnadleCall1 = () => {
    window.location.href = "tel:+1 913 713 8560";
  };
  const hnadleCall2 = () => {
    window.location.href = "tel:+1 913 713 8560";
  };
 
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
    .sendForm("service_t7ykraq", "template_l7p1tlt", e.target, {
      publicKey: "SwdUDywDY-ut_50nH",
    })
      .then(
        () => {
          toast.success("Form Submitted Successfully!", {
            position: "top-right",
            autoClose: 3000, // 3 seconds
          });
          console.log("SUCCESS!");
          e.target.reset();
        },
        (error) => {
          toast.error("Something went wrong. Please try again.", {
            position: "top-center",
            autoClose: 3000,
          });
          console.log("FAILED...", error.text);
        }
      );
  };
  
  

  return (
    <div>
      <NavBar title={"Get in touch with us"} image={ContactIMG} />
      <div className="Circle1"></div>
      <div className="Circle2"></div>
      <div className="Main_Contact_Bg">
        <div className="Form_Main">
          <div className="Form_Details">
            <h1>We’d love to hear from you!</h1>
            <div>
              <p>Get in touch</p>

              <form className="Forms" onSubmit={handleSubmit}>
                <div className="TwoFeilds_Form">
                  <div className="Form_feild">
                    <label for="firstname">FIRST NAME</label>
                    <input
                      type="text"
                      placeholder="Enter your name here"
                      id="firstname"
                      name="firstname"
                      required
                    />
                  </div>

                  <div className="Form_feild">
                    <label for="secondname">SECOND NAME</label>
                    <input
                      type="text"
                      placeholder="Enter your name here"
                      id="secondname"
                      name="secondname"
                      required
                    />
                  </div>
                </div>
                <div className="TwoFeilds_Form">
                  <div className="Form_feild">
                    <label for="Email">EMAIL</label>
                    <input
                      type="email"
                      placeholder="Enter your mail here"
                      id="Email"
                      name="email"
                      required
                    />
                  </div>

                  <div className="Form_feild phone_number">
                    <label for="PhNumber">PHONE NUMBER</label>
                    <input
                      type="number"
                      placeholder="Enter your phone number here"
                      id="PhNumber"
                      name="phoneNumber"
                      required
                    />
                  </div>
                </div>
                <div>
                  <div className="Form_feild">
                    <label for="Message">MESSAGE</label>
                    <input
                      type="text"
                      placeholder="Enter your Message here"
                      id="Message"
                      name="message"
                      className="textarea_input"
                    />
                  </div>
                  <br />
                  <div className="Form_feild">
                    <button type="submit">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="Chat_With_Us">
            <div className="Chat_Us">
              <div className="Icon">
                <img src={chat} alt="" className="chat_svg_images" />
              </div>
              <div>
                <h1>Chat with us</h1>
                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
                <span onClick={handleEmail}> Info@vsdltech.com</span>
              </div>
            </div>

            <div className="Chat_Us">
              <div className="Icon">
                {/* <RiUserLocationLine /> */}
                <img src={location} alt="" className="chat_svg_images" />
              </div>
              <div>
                <h1>Visit us</h1>
                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
                <div>
                  <span>4100 Spring Valley, </span>
                  <span>Suite # 148, Dallas, </span>
                  <span>TX 75244</span>
                </div>
              </div>
            </div>

            <div className="Chat_Us">
              <div className="Icon">
                <img src={call} alt="" className="chat_svg_images" />
              </div>
              <div>
                <h1>Call us</h1>
                {/* <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry.
                </p> */}
                <div>
                  <span onClick={hnadleCall1}>+1 913 713 8560</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
