import "./Home.css";
import Globe from "../../Assets/Home/Rectangle_76-removebg-preview.png";
import Logo1 from "../../Assets/Home/Group 73.svg";
import Logo2 from "../../Assets/Home/Group 86.svg";
import Logo3 from "../../Assets/Home/Group 67.svg";
import Logo4 from "../../Assets/Home/Group 87.svg";
import Logo5 from "../../Assets/Home/Group 77.svg";
import Logo6 from "../../Assets/Home/Group 75.svg";
import TrianglWithCircle from "../../Assets/Home/Group 1000005511.png";
import { FaArrowRight } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import VSDL from "../../Assets/Home/VSDL Logo.png";
import TeamImage from "../../Assets/Home/team-working-together-project 1.png";
import Reliable from "../../Assets/Home/Group 119.png";
import Empow from "../../Assets/Home/Group 120.png";
import Prof from "../../Assets/Home/Group 121.png";
import scal from "../../Assets/Home/Group 122.png";
import Precise from "../../Assets/Home/Group 123.png";
import PreFooter from "../PreFooter/PreFooter";
import Footer from "../Footer/Footer";
import { useContext, useEffect, useRef, useState } from "react";
import { dataShare } from "../../App";
import Scrooling from "../ScrollToTop/Scroll";
import GlassImg from "../../Assets/Home/Rectangle 131.png";
import CircularImage from "../../Assets/Home/Rectangle 127.png";
import BallsImage from "../../Assets/Home/Rectangle 136.png";
import NavBar from "../NavBar/NavBar";
import VSDLLogo from "../../Assets/Home/VSDL Logo.png";

import { ImCross } from "react-icons/im";
import { FaBars } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";

const Home = () => {
  const HImage1 = useRef();
  const HImage2 = useRef();
  const HImage3 = useRef();
  const HImage4 = useRef();

  // const DIVContent = [HImage1, HImage2, HImage3, HImage4];
  const [index, setindex] = useState(0);
  // const [Scrool, setScrool] = useState({scrollX:0,scrollY:0});

  useEffect(() => { }, []);

  const navigate = useNavigate();
  const { setIndex, setScrool } = useContext(dataShare); //Use Context Data

  // const [navbarColor, setnavbarColor] = useState("");

  // const handleScroll = () => {
  //   if (window.scrollY > 650) {
  //     setnavbarColor("rgba(11, 20, 101, 1)");
  //   } else {
  //     setnavbarColor("");
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  const handleClick = () => {
    const isMobile = window.innerWidth < 720;
    const scrollPosition = isMobile ? { X: 0, Y: 300 } : { X: 0, Y: 600 };
    navigate("/Services");
    setScrool(scrollPosition);
  };

  const [active, setactive] = useState(false);

  const handleClickNav = () => {
    setactive(!active);
  };

  const CardData = [
    {
      id: 1,
      image: Logo1,
      title: "Oracle Fusion Cloud",
      desc: "Streamline your enterprise operations with Oracle Fusion Cloud. Achieve efficiency and real-time insights across finance, HR, and supply chain.",
    },
    {
      id: 2,
      image: Logo2,
      title: "Salesforce",
      desc: "Maximize customer relationships with tailored Salesforce solutions. Boost sales, automate marketing, and enhance customer loyalty effortlessly.",
    },
    {
      id: 3,
      image: Logo3,
      title: "Workday",
      desc: "Transform workforce management with Workday’s intuitive platform. Manage HR, payroll, and finance with real-time data insights for better decision-making.",
    },
    {
      id: 4,
      image: Logo4,
      title: "Data Engineering",
      desc: "Unlock the potential of your data with our Data Engineering services. Build robust pipelines for seamless integration and actionable insights.",
    },
    {
      id: 5,
      image: Logo5,
      title: "Java FullStack",
      desc: "Create scalable applications with our Java Full-Stack expertise. Deliver high-performance solutions from front-end design to back-end functionality.",
    },
    {
      id: 6,
      image: Logo6,
      title: "ServiceNow",
      desc: "Enhance IT service management with ServiceNow solutions. Streamline workflows, automate processes, and improve user experiences across your organization.",
    },
  ];

  const ChooseData = [
    {
      Img: Reliable,
      Content: "Reliable",
    },
    {
      Img: Empow,
      Content: "Empowering",
    },
    {
      Img: Prof,
      Content: "Proficient",
    },
    {
      Img: scal,
      Content: "Scaling",
    },
    {
      Img: Precise,
      Content: "Precise",
    },
  ];

  const ImageArray = [
    // <div className="Image1_Content_Container" ref={HImage1}>
    //   <img src={GlassImg} alt="glass" />
    //   <div className="Content_img1">
    //     <div className="VerticalLine"></div>
    //     <h1>World of &nbsp; new solutions</h1>
    //   </div>
    // </div>,

    // <div className="Image2_Content_Container" ref={HImage2}>
    //   <img src={CircularImage} alt="Slides" />
    //   <div className="Content_img2">
    //     <h1>Real solutions, Artificial intelligence</h1>
    //     <p>
    //     Utilizing advanced AI technologies to deliver intelligent, scalable solutions that drive real business outcomes
    //     </p>
    //     <button onClick={handleClick}>Know more</button>
    //   </div>
    // </div>,

    // <div className="Image3_Content_Container" ref={HImage3}>
    //   <img src={BallsImage} alt="Balls" />
    //   <div className="Content_img3">
    //     <h1>Growing throughout Innovation</h1>
    //     <p>Delivering transformative digital solutions that accelerate growth and drive your business forward.
    //     </p>
    //     <button onClick={handleClick}>Know more</button>
    //   </div>
    // </div>,

    <div className="Globe_div" ref={HImage4}>
      <img src={Globe} alt="globe" id="GlobeImage" />
      <div className="Content_Img">
        <h1>Enhancing your business digitally</h1>
        <button onClick={handleClick}>MORE</button>
      </div>
    </div>,
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (index >= ImageArray.length - 1) {
        setindex(0);
      } else {
        setindex(index + 1);
      }
    }, 10000);
    return () => clearInterval(interval);
  }, [ImageArray]);

  const handleCards = (ind) => {
    setIndex(ind);
    setScrool({ X: 0, Y: 600 });
  };
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY == 0 ? setIsScrolled(false) : setIsScrolled(true);
    };

    window.addEventListener("scroll", handleScroll);

    // Prevent browser from restoring scroll position
    //  if ("scrollRestoration" in history) {
    //    history.scrollRestoration = "manual";
    //  }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="Home_Main">
      <Scrooling />
      <div className="MH_Nav_Main">
        <div className={isScrolled ? "Nav_Bar" : "Nav_Bar_hide"}>
          <div className="MH_Nav_Bar">
            <Link style={{ textDecoration: "none" }} to="/">
              <img src={VSDL} alt="Logo" />
            </Link>
            {active ? (
              <div>
                <ImCross className="CrossMark" onClick={handleClickNav} />
              </div>
            ) : (
              <div>
                <FaBars className="CuroselIcon" onClick={handleClickNav} />
              </div>
            )}
          </div>
          <ul className={`Nav_Links ${active ? "shownavLinks" : "noNavLink"}`}>
            <li>
              <Link
                to="/"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Home
              </Link>
            </li>
            <li className="who-we-are-home">
              <Link
                to="/WhoWeAre"
                style={{
                  textDecoration: "none",
                  color: "#DFE3FB",
                }}
                onClick={handleClickNav}
              >
                Who We Are
              </Link>
            </li>
            <li className="what_we_do_home">
              <Link
                to="/Services"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                What We Do
              </Link>
            </li>
            <li>
              <Link
                to="/Blogs"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/Careers"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                to="/Contact"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <NavBar /> */}

      {/* <div className="navbar">
          <div className="CuroselAndImg">
          <Link to="/"><img src={VSDLLogo} alt="VsdlLogo"></img></Link>
          {
            active ? 
            <div><ImCross className="CrossMark" onClick={handleClickNav}/></div>
            :
            <div><FaBars className="CuroselIcon" onClick={handleClickNav}/></div> 
          }

          </div>
          <ul className={`navItems ${active ? "shownavItems" : "noNav"}` }>
            <li>
              <Link to="/" className="Nav_links" onClick={handleClickNav}>
                Home
              </Link>
            </li>
            <li>
              <Link
              className="Nav_links"
              to="/WhoWeAre"
              onClick={handleClickNav}
              >
                Who we are
              </Link>
            </li>
            <li>
              <Link
                to="/Services"
                className="Nav_links"
                onClick={handleClickNav}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                to="/Blogs"
                className="Nav_links"
                onClick={handleClickNav}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/Careers"
                className="Nav_links"
                onClick={handleClickNav}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                to="/Contact"
                className="Nav_links"
                onClick={handleClickNav}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div> */}

      <div className="Image_Content">
        {ImageArray[index]}

        {/* 1st Image  */}

        {/* <div className="Image1_Content_Container" ref={HImage1}>
       <img src = {GlassImg} alt="glass"/>
       <div className="Content_img1">
       <div className="VerticalLine"></div>
        <h1>World of &nbsp; new solutions</h1>
       </div>
      </div> */}

        {/* 2nd Image  */}

        {/* <div className="Image2_Content_Container" ref={HImage2}>
       <img src = {CircularImage} alt="Slides"/>
       <div className="Content_img2">
        <h1>Real solutions, &nbsp; Artificial intelligence</h1>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        <button onClick={handleClick}>Know more</button>
       </div>
      </div> */}

        {/* 3rd Image  */}

        {/* <div className="Image3_Content_Container" ref={HImage3}>
       <img src = {BallsImage} alt="Balls"/>
       <div className="Content_img3">
        <h1>Growing throughout Innovation</h1>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        <button onClick={handleClick}>Know more</button>
       </div>
      </div> */}

        {/* 4th Image  */}

        {/* <div className="Globe_div" ref={HImage4}>
          <img src={Globe} alt="globe" id="GlobeImage" />
          <div className="Content_Img">
            <h1>Enhancing your business digitally</h1>
            <button onClick={handleClick}>MORE</button>
          </div>
        </div> */}
      </div>

      <div className="Text_Content">
        <div>
          <h1>Success thrives <br /> on clarity & foresight</h1>
        </div>
        <div>
          <p>Partnering for progress, transforming challenges into opportunities.
          </p>
        </div>
      </div>
      <div className="Services-Container">
        <h1>A wide range of services</h1>
        <div className="Cards_Conatiner">
          {CardData.map((item, ind) => {
            return (
              <div key={item.id} className="Card" id="text">
                <div className="Single_Card">
                  <img src={item.image} alt="Logo"></img>
                  {/* <div className="title_div_wrapper"> */}
                  <h2>{item.title}</h2>
                  {/* </div> */}
                  <p>{item.desc}</p>
                  <div>
                    <Link to={"/Services"}>
                      <FaArrowRight onClick={() => handleCards(ind)} className="home_arrow_icon" />
                    </Link>
                  </div>
                </div>
              </div>  
            );
          })}
        </div>
        <button
          className="explore-more"
          style={{ textDecoration: "none" }}
          onClick={handleClick}
        >
          Explore more
        </button>
        {/* <img
          src={TrianglWithCircle}
          className="Images11"
          alt="TriangleCircle"
        ></img> */}

        <div className="triangle_canvas">
        </div>
        <div className="Images11">
        </div>
      </div>
      {/* <div className="traingles_div"> */}

      {/* </div> */}
      <div className="Main_Team_Container">
        <div className="Team_Container">
          <div className="Team_Content">
            <p>WHO WE ARE</p>
            <h1>We’re partners in transformation</h1>
            <h4>
              At VSDL Technologies, we redefine the landscape of IT consulting, digital transformation, and technology solutions.
           <div className="who_home_hr"></div>
              <h4> Our expert team excels in data science, cutting-edge design, and advanced artificial intelligence, crafting innovative solutions that fuel your growth and enhance efficiency.</h4>
            </h4>
            <h4>
              We offer tailored IT consulting services that optimize your technology infrastructure, ensuring your systems are secure, efficient, and strategically aligned with your business objectives. Let's embark on a transformative journey together!
            </h4>
            {/* <h4>
              We offer comprehensive IT consulting services to optimize your
              technology infrastructure, ensuring your systems are efficient,
              secure, and aligned with your business goals.
            </h4> */}
          </div>
          <div className="Team_Image">
            <img src={TeamImage} alt="Team"></img>
          </div>
        </div>
      </div>
      <div className="Choose_Main">
        <h3>Why choose us?</h3>
        <div className="Chh_Map">
          {ChooseData.map((items) => {
            return (
              <div className="Choose_Us">
                <img src={items.Img} alt=""></img>
                <p>{items.Content}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div className="Embrace_Container_Main">
        <div className="Embrace_Container">
          <h1>Embrace Innovation.<br/> From Concept to Launch.</h1>
          <div></div>
          <p>
            Together, let’s build a future where technology propels your
            success.
          </p>
          <p>
            At VSDL Technologies, our creative and dedicated team is committed
            to turning your vision into reality. Let’s innovate together and
            elevate your business to new heights!
          </p>
        </div>
      </div>
      <PreFooter />
      <Footer />
    </div>
  );
};
export default Home;
