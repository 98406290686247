import React, { useEffect } from "react";
import "./PreFooter.css";
import section4img from "../../Assets/PreFooter/Group 118.png";
import call from "../../Assets/PreFooter/Vector 16.png";
import mail from "../../Assets/PreFooter/Group 134.png";
import code from "../../Assets/PreFooter/Group 135.png";
import mail2 from "../../Assets/PreFooter/mail2.svg";
import Aos from "aos";
import "aos/dist/aos.css";

export default function PreFooter() {
  useEffect(() => {
    Aos.init({
      duration: 1200, // Duration of animations
      easing: "ease",
    });
  }, []);

  const handleCall = () => {
    window.location.href = "tel:+1 913 713 8560";
  };
  const handleEmail1 = () => {
    window.location.href = "mailto:hey@vsdl.com";
  };
  const handleEmail2 = () => {
    window.location.href = "mailto:careers@vsdl.com";
  };

  return (
    <section className="PreFoter-section">
      <img
        src={section4img}
        alt="section4-img"
        data-aos="fade-up"
        data-aos-delay="200"
      />
      <h1>Let us know if we can help you!</h1>
      <div className="Addres">
        <p>4100 Spring Valley,</p>
        <p>Suite #148, Dallas, </p>
        <p>TX 75244</p>
      </div>
      <div className="group-container">
        {/* <div className="group-container-box" id="group-container-box2" onClick={handleEmail1}>
          <img src={code} alt="code-img" />
          <p style={{ fontFamily: "Helvetica Now Display Medium" }}>
            hey@vsdl.com
          </p>
        </div> */}
        <div className="group-container-box group-box" onClick={handleCall}>
          <img src={call} alt="call-img" />
          <p>+1 913 713 8560</p>
        </div>
        <div
          className="group-container-box group-box"
          id="group-container-box1"
          onClick={handleEmail2}
        >
          <img src={mail2} alt="mail-img" className="mail_img" />
          <p style={{ fontFamily: "Helvetica Now Display Medium" }}>
            info@vsdltech.com
          </p>
        </div>
      </div>
    </section>
  );
}
