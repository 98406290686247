import { useEffect } from "react"
import { useLocation } from "react-router-dom"


const Scrooling = ({ scrollX = 0, scrollY = 0 })=>{
    const {AboutUs,Services,Blogs,Careers,Contact} = useLocation()

        useEffect(()=>{
            window.scrollTo(scrollX,scrollY)
        },[AboutUs,Services,Blogs,Careers,Contact,scrollX,scrollY])

    return null
}

export default Scrooling