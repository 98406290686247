import React from 'react'
import "./Footer.css";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { useNavigate } from 'react-router-dom'
export default function Footer() {
  const navigate = useNavigate()
  const handleNavigate = ()=>{
navigate("/PrivacyPolicy")
  }
  const handleTermsAndConditions =()=>{
    navigate("/TermsAndConditions")
  }

  const handleInsta = ()=>{
    window.location.href=""
  }
  const handleLinkedIn = ()=>{
    window.location.href="https://www.linkedin.com/company/vsdl-technologies-inc/"
  }
  return (

    <footer className='over-all-footer'>
    <p onClick={handleNavigate}>Privacy Policy</p>
    <p className='footer-content' onClick={handleTermsAndConditions}>Terms & Conditions</p>
    <p>© 2024 VSDL</p>
    <div onClick={handleInsta} className='IconDiv'>
    <FaInstagram  className='coloring'/>
    </div>
    <div onClick={handleLinkedIn} className='IconDiv'>
    <FaLinkedin className='coloring'/>
    </div>
    
    </footer>
  )
}
