import React, { useState } from "react";
import "./PrivacyPolicy.css";
import NavBar from "../NavBar/NavBar";
import VSDL from "../../Assets/Home/VSDL Logo.png";
import { Link } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { FaBars } from "react-icons/fa";
import Footer from "../Footer/Footer"
import Scrooling from "../ScrollToTop/Scroll";

const PrivacyPolicy = () => {
  const [active, setactive] = useState(false);
  const handleClickNav = () => {
    setactive(!active);
  };
  const handleEmail1 = () => {
    window.location.href = "mailto:hi@vsdltechnologies.com";
  };
  return (
    <div className="privacypolicy-main-container">
      <div className="MH_Nav_Main">
        <div className="Nav_Bar">
          <div className="MH_Nav_Bar">
            <Link style={{ textDecoration: "none" }} to="/">
              <img src={VSDL} alt="Logo" />
            </Link>
            {active ? (
              <div>
                <ImCross className="CrossMark" onClick={handleClickNav} />
              </div>
            ) : (
              <div>
                <FaBars className="CuroselIcon" onClick={handleClickNav} />
              </div>
            )}
          </div>
          <ul className={`Nav_Links ${active ? "shownavLinks" : "noNavLink"}`}>
            <li>
              <Link
                to="/"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Home
              </Link>
            </li>
            <li className="who-we-are-home">
              <Link
                to="/WhoWeAre"
                style={{
                  textDecoration: "none",
                  color: "#DFE3FB",
                }}
                onClick={handleClickNav}
              >
                Who We Are
              </Link>
            </li>
            <li className="what_we_do_home">
              <Link
                to="/Services"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                What We Do
              </Link>
            </li>
            <li>
              <Link
                to="/Blogs"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/Careers"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                to="/Contact"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="privacypolicy-container">
        <h1>Privacy Policy</h1>
        <p>
          We value your visiting our website and using our Brand VSDL
          Services. The what, how, and why of the information we gather from you
          when you use our services or visit our website are all explained in
          this policy. It also describes how we specifically utilize and
          disclose that data. We always protect your privacy by not disclosing
          any personal information we may have obtained from your use of our
          website.
        </p>
        <p>
          You may read about our rules on this page about how we will collect,
          use, and disclose personal information about you when you use our
          services. Your information won't be used or shared with anybody else
          than what this privacy policy specifies.
        </p>
        <p>
          Your Personal Information is used by us to deliver and enhance the
          Service. You consent to the collection and use of information in line
          with this policy by using the Service. Terms used in this Privacy
          Policy have the same meanings as those found in our Terms and
          conditions, unless otherwise indicated.
        </p>
        <p>
          We may request certain personally identifying information from you
          while you use our service so that we can get in touch with or identify
          you. Your name, phone number, company information, and job title are
          just a few examples of personally identifying information that may be
          disclosed.
        </p>
        <h2>To encourage you and others to use our services.</h2>
        <p>
          Furthermore, we might utilise the data we gather to market to you
          about our services or recommend other deals we have for you to take
          advantage of. We might also use your Personal Information to market
          our services to prospective customers and other users who might be
          similar to you.
        </p>
        <h2>
          To provide you with promotional and informative material that you are
          free to accept.
        </h2>
        <p>
          If you would want to no longer receive our promotional emails, simply
          follow the unsubscribe instructions found in each email.
        </p>
        <h2>Cookies</h2>
        <p>
          Cookies are little data files that could contain a unique, anonymous
          identity. Cookies are files that websites send to your browser and
          store on the hard disk of your computer.
        </p>
        <p>
          "Cookies" are tools we employ to gather data. You may set your browser
          to alert you when a cookie is being sent or to reject cookies
          altogether. On the other hand, you might only be able to use some of
          our websites if you reject cookies.
        </p>
        <h2>Service Providers</h2>
        <p>
          We may utilize outside businesses and people to help us with our
          website, to supply information on our behalf, carry out
          website-related tasks, or to help us with user analytics.
        </p>
        <p>
          These third parties are required to keep your personal information
          private and are only permitted access to it for the purposes of
          carrying out these duties on our behalf.
        </p>
        <h2>Security</h2>
        <p>
          We take reasonable and adequate precautions, keeping in mind the risks
          associated with processing and the nature of the Personal Information,
          to guard against loss, misuse, and unauthorised access, disclosure,
          modification, and destruction. Although we care about the security of
          your personal information, keep in mind that no method of electronic
          data transfer over the Internet or technique of electronic data
          storage is 100% safe. We can't guarantee the complete security of your
          personal information, even though we make every effort to safeguard it
          using commercially reasonable methods.
        </p>
        <h2>Modifications To This Privacy Statement</h2>
        <p>
          This privacy statement is subject to change at any time and from time
          to time. The version date at the bottom of this Privacy Policy
          indicates the most recent version of the document. Any revisions or
          modifications take effect right away upon notification, which we may
          provide in a number of ways, such as by publishing a new copy of this
          privacy statement or another notice on the website, among other ways.
        </p>
        <p>
          As your ongoing use of the website indicates that you agree to be
          governed by this privacy policy going forward, we encourage you to
          review it frequently to be informed of changes that may impact you.
          The versions of this privacy policy that were in effect on each date
          you visited the website are considered to be genuine, complete,
          legitimate, original, and enforceable copies that we have saved
          electronically or in another appropriate manner.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you would like to update, remove, or modify any Personal
          Information that we may have about you, or if you have any concerns
          regarding how we have handled any privacy-related issue, please write
          us an email or letter at: (<span style={{cursor:"pointer"}} onClick={handleEmail1}>hi@vsdltechnologies.com</span>).
        </p>
        <p>Goodtobuy Properties is the parent company of VSDL.</p>
        <p>7304 W. 130th Street,
        <br />
        Suite #220 Overland park
        <br />
        New York(NY), 10941
        <br/>
        <span style={{cursor:"pointer"}} onClick={handleEmail1}>hi@vsdltechnologies.com</span>
        <br />

        </p>
        <p>
          We appreciate your interest in learning about VSDL's privacy policy
          and your confidence in us to look after your brand.
        </p>
      </div>
      <Footer/>
      <Scrooling/>
    </div>
  );
};

export default PrivacyPolicy;
