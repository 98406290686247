// import React, { useEffect, useState } from "react";
// import NavBar from "../NavBar/NavBar";
// import BlogNav from "../../Assets/NavBar/BlogNav.jpg";
// import "./Blogs.css";
// import TopBlog from "../../Assets/Blogs/Rectangle 16.png";
// import RcB1 from "../../Assets/Blogs/Rectangle 15.png";
// import RcB2 from "../../Assets/Blogs/Rectangle 14.png";
// import RcB3 from "../../Assets/Blogs/Rectangle 13.png";
// import Ellipse1 from "../../Assets/Blogs/Ellipse 3.png";
// import Ellipse2 from "../../Assets/Blogs/Ellipse 4.png";
// import Footer from "../Footer/Footer";
// import PreFooter from "../PreFooter/PreFooter";
// import {database, storage, ref, set,push, storageRef, uploadBytes, getDownloadURL} from "../firebase/firebase";

// export default function Blogs() {
//   const [isModalOpen, setIsModalOpen] = useState(false); // added state variable
//   const [blogData, setBlogData] = useState({
//     title:"",
//     content:"",
//     authorName:"",
//     image:null,
//   })

//   const RecentBlogsInfo = [
//     {
//       Image: RcB1,
//       Title: "Why large industries are moving to react",
//       Discription:
//         "Large companies create big enterprise applications for a large and critical userbase and they are very expensive to build.",
//       AutherName: "Robert Bowney Jr",
//       Date: "24th May 2024",
//     },
//     {
//       Image: RcB2,
//       Title: "Is It the Best time to become a JavaScript Developer?",
//       Discription:
//         "During earlier days JavaScript — and web development in general — when everything felt brittle.",
//       AutherName: "Robert Bowney Jr",
//       Date: "24th May 2024",
//     },
//     {
//       Image: RcB3,
//       Title: "Career move — how to become a UI Engineer",
//       Discription:
//         "Starting or switching careers in UI Engineering requires navigating its vast ecosystem.",
//       AutherName: "Robert Bowney Jr",
//       Date: "24th May 2024",
//     },
//   ];

//   const handleApply = () => {
//     console.log('handleApply called')
//     setIsModalOpen(true); // added function to open modal
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false); // added function to close modal
//   };

// useEffect (()=>{
//   if(isModalOpen){
//     document.body.classList.add("modal-open")
//   }else{
//     document.body.classList.remove("modal-open")
//   }
// },[isModalOpen])
//   return (
//     <div>
//       <NavBar title={"Know it all from the blogs"} image={BlogNav} />
//       <div className="CircleBlog1"></div>
//       <div className="CircleBlog2"></div>

//       <div className="Main_Blog_Bg">

//         <div className="Blog_Main">
//           <div>
//             <h3>Top blogs</h3>
//             <div className="Top_Blogs_Container">
//               <div className="Blog_Container">
//                 <div className="sub_Blog_Container1">
//                   <img src={TopBlog} alt="Blog_Img"></img>
//                   <div className="Top_Blog_Content">
//                     <h1>Common Security Vulnerabilities</h1>
//                     <p>
//                       As Troy Hunt so eloquently puts it: “The theory goes like
//                       this: Expect any un-trusted data to be malicious. What’s
//                       un-trusted data?  Anything that originates from outside
//                       the system and you don’t have absolute control over so
//                       that includes form data, query strings, cookies, other
//                       request headers.
//                     </p>
//                     <div className="Blog_person_Info">
//                       <p className="person1">
//                         <img src={Ellipse1} alt="Elipse"></img>
//                         <p>Robert Bowney Jr</p>
//                       </p>
//                       <p className="person2">
//                         <img src={Ellipse2} alt="Elipse"></img>
//                         <p>24th May 2024</p>
//                       </p>
//                     </div>
//                     <button>Read more</button>
//                   </div>
//                 </div>
//                 <div className="sub_Blog_Container2">
//                   <section>
//                     <h2>Categories</h2>
//                     <p>Angular</p>
//                     <p>GraphQL</p>
//                     <p>Java Script</p>
//                     <p>Node</p>
//                     <p>Oracle cloud</p>
//                     <p>React</p>
//                     <p>Web development</p>
//                   </section>
//                   <section>
//                     <h2>Archives</h2>
//                     <p>2023</p>
//                     <p>2022</p>
//                     <p>2021</p>
//                   </section>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div>
//             <h3>Recent Blogs</h3>
//             <div className="Recent_Blogs_Container">
//               {RecentBlogsInfo.map((Card) => (
//                 <div className="Single_Blog_Container">
//                   <img src={Card.Image} alt="AI_Img"></img>
//                   <span>{Card.Title}</span>
//                   <p>{Card.Discription}</p>
//                   <div className="Blog_Persons_Info">
//                     <p className="persons1">
//                       <img src={Ellipse1} alt="Elipse"></img>
//                       <p>{Card.AutherName}</p>
//                     </p>
//                     <p className="persons2">
//                       <img src={Ellipse2} alt="Elipse"></img>
//                       <p>{Card.Date}</p>
//                     </p>
//                   </div>
//                 </div>
//               ))}
//             </div>
//             <div className="Main_Button">
//               <button className="Main_Btn"  onClick={handleApply}>Contribute now</button>
//             </div>
//           </div>
//         </div>
//       </div>
//       <div className="Blog_Footer">
//       <PreFooter />
//       <Footer />
//       </div>

//       {isModalOpen && (
//         <div className="application-modal">
//           <div className="application-modal-content">
//           <button className="close-button" onClick={handleCloseModal}>&times;</button>
//             <h2>Contribute</h2>
//             <form>
//               <label>Title:</label>
//               <input type="text" placeholder="Enter your title" required />
//               <label>Content:</label>
//               <textarea style={{resize:"none"}} placeholder="Write your content"></textarea>
//               <label>Author Name:</label>
//               <input type="text" placeholder="Enter your name" required />
//               <label>Image:</label>
//               <input type="file" required />
//               <button type="submit">Submit</button>
//             </form>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

import React, { useContext, useEffect, useState } from "react";
import NavBar from "../NavBar/NavBar";
import BlogNav from "../../Assets/NavBar/BlogNav.jpg";
import "./Blogs.css";
import TopBlog from "../../Assets/Blogs/Rectangle 16.png";
import oracle from "../../Assets/Blogs/Oracle Cloud Infrastructure.svg";
import RcB1 from "../../Assets/Blogs/Rectangle 15.png";
import RcB2 from "../../Assets/Blogs/Rectangle 14.png";
import RcB3 from "../../Assets/Blogs/Rectangle 13.png";
import Ellipse1 from "../../Assets/Blogs/Ellipse 3.png";
import Ellipse2 from "../../Assets/Blogs/Ellipse 4.png";
import Footer from "../Footer/Footer";
import PreFooter from "../PreFooter/PreFooter";
import {
  database,
  storage,
  ref,
  set,
  push,
  storageRef,
  uploadBytes,
  getDownloadURL,
  get,
} from "../firebase/firebase";
import { dataShare } from "../../App";

export default function Blogs() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [blogBool,setblogBool] = useState(true)
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    authorName: "",
    image: null,
  });

  const {setScrool} = useContext(dataShare);
  useEffect(()=>{
    setScrool({X:0,Y:0})
  },[]);

  const Blog = blogBool ? blogs.slice(0,3) : blogs.slice(0,blogs.length)
  const handleBool = ()=>{
    // console.log("change BOOL")
    setblogBool(!blogBool)
  }
  console.log(blogBool,"boolean")

  const RecentBlogsInfo = [
    {
      imageUrl: RcB1,
      title: "Why large industries are moving to react",
      content:
        "Large companies create big enterprise applications for a large and critical userbase and they are very expensive to build.",
      authorName: "Robert Bowney Jr",
      date: "24th May 2024",
    },
    {
      imageUrl: RcB2,
      title: "Is It the Best time to become a JavaScript Developer?",
      content:
        "During earlier days JavaScript — and web development in general — when everything felt brittle.",
      authorName: "Robert Bowney Jr",
      date: "24th May 2024",
    },
    // {
    //   imageUrl: RcB3,
    //   title: "Career move — how to become a UI Engineer",
    //   content:
    //     "Starting or switching careers in UI Engineering requires navigating its vast ecosystem.",
    //     authorName: "Robert Bowney Jr",
    //   date: "24th May 2024",
    // },
  ];
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Upload image to Firebase Storage
    const imageRef = storageRef(storage, `blog_images/${formData.image.name}`);
    await uploadBytes(imageRef, formData.image);
    const imageUrl = await getDownloadURL(imageRef);

    // Store blog data in Firebase Realtime Database
    const blogRef = push(ref(database, "blogs"));
    await set(blogRef, {
      title: formData.title,
      content: formData.content,
      authorName: formData.authorName,
      imageUrl,
      date: new Date().toLocaleDateString(),
    });

    alert("Blog submitted successfully!");
    fetchBlogs()
    setIsModalOpen(false);
  };
  const handleApply = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };



  const fetchBlogs = async () => {
    const blogRef = ref(database, "blogs");
    const snapshot = await get(blogRef);
    if (snapshot.exists()) {
      setBlogs(Object.values(snapshot.val()));
      // setBlogs([...RecentBlogsInfo, Object.values(snapshot.val())])
    }
  };
  useEffect(() => {

    fetchBlogs();
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isModalOpen]);

  return (
    <div>
      <NavBar title={"Know it all from the blogs"} image={BlogNav} />
      <div className="CircleBlog1"></div>
      <div className="CircleBlog2"></div>

      <div className="Main_Blog_Bg">
        <div className="Blog_Main">
          <div>
            <h3>Top blogs</h3>
            <div className="Top_Blogs_Container">
              <div className="Blog_Container">
                <div className="sub_Blog_Container1">
                  <img src={TopBlog} alt="Blog_Img"></img>
                  <div className="Top_Blog_Content">
                    <h1>Oracle Cloud</h1>
                    <p>
                      Oracle Cloud provides a complete suite of integrated
                      services, including computing, storage, networking, and
                      database solutions. Designed for high performance,
                      security, and scalability, it empowers businesses to
                      deploy, manage, and integrate modern applications
                      efficiently.
                    </p>
                    <div className="Blog_person_Info">
                      <p className="person1">
                        <img src={Ellipse1} alt="Elipse"></img>
                        <p>Robert Bowney Jr</p>
                      </p>
                      <p className="person2">
                        <img src={Ellipse2} alt="Elipse"></img>
                        <p>24th May 2024</p>
                      </p>
                    </div>
                    <button>Read more</button>
                  </div>
                </div>
                <div className="sub_Blog_Container2">
                  <section>
                    <h2>Categories</h2>
                    <p>Oracle Cloud</p>
                    <p>Sales Force</p>
                    <p>AI/ML</p>
                    <p>Data Engineer</p>
                  </section>
                  <section>
                    <h2>Archives</h2>
                    <p>2023</p>
                    <p>2022</p>
                    <p>2021</p>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <div className="BlogsMainDiv">
            <h3>Recent Blogs</h3>
            <div className="Recent_Blogs_Container">
              {Blog.map((Card, index) => (
                <div className="Single_Blog_Container" key={index}>
                  <div>
                    <img
                      src={Card.imageUrl}
                      alt="AI_Img"
                      className="BlogImage"
                    ></img>
                  </div>
                  <div>
                    <p className="BlogTitle">{Card.title}</p>
                  </div>
                  <div>
                    <p className="BlogContent">{Card.content}</p>
                  </div>
                  <div className="Blog_Persons_Info">
                    <p className="persons1">
                      <img src={Ellipse1} alt="Elipse"></img>
                      <p>{Card.authorName}</p>
                    </p>
                    <p className="persons2">
                      <img src={Ellipse2} alt="Elipse"></img>
                      <p>{Card.date}</p>
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <div style={{ textAlign: "center" }}>
              <button onClick={handleBool} className="ViewMoreBlogsBTN">
                {blogBool ? "View More" : "View Less"}
              </button>
            </div>
            {/* <div className="Main_Button">
              <button className="Main_Btn" onClick={handleApply}>
                Contribute now
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="Blog_Footer">
        <PreFooter />
        <Footer />
      </div>


    </div>
  );
}
