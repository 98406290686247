import React, { useState, useEffect, useContext } from "react";
import "./Services.css";
import NavBar from "../NavBar/NavBar";
import Image from "../../Assets/NavBar/Rectangle 136......png";
import Image12 from "../../Assets/Services/what_are_we_do.svg";
import PreFooter from "../PreFooter/PreFooter";
import Footer from "../Footer/Footer";
import { dataShare } from "../../App";
import { useNavigate, useParams } from "react-router-dom";
import Logo1 from "../../Assets/Services/Group 73.svg";
import Logo2 from "../../Assets/Services/Group 74.svg";
import Logo3 from "../../Assets/Services/Group 75.svg";
import Logo4 from "../../Assets/Services/Group 77.svg";
import Logo5 from "../../Assets/Services/Group 86.svg";
import Logo6 from "../../Assets/Services/Group 87.svg";

import Modal from "react-modal";
import Scrooling from "../ScrollToTop/Scroll";
import { FaSalesforce } from "react-icons/fa6";
import { GrDocumentPerformance, GrServices, GrWorkshop } from "react-icons/gr"
import { RiJavaLine } from "react-icons/ri";
import { MdDataExploration, MdDatasetLinked, MdOutlineDesignServices, MdOutlineHandyman } from "react-icons/md";
import { DiDigitalOcean } from "react-icons/di";
import { SiAzuredevops, SiEnterprisedb, SiWesterndigital } from "react-icons/si";
import { AiOutlineCloudServer } from "react-icons/ai";
import { GiArtificialIntelligence, GiCyberEye } from "react-icons/gi";
import { TbDatabaseStar } from "react-icons/tb";
import { SiOracle } from "react-icons/si";
import { BsCloudFill } from "react-icons/bs";
function Services() {
  // const Images = [
  //   <FaSalesforce />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,
  //   <GrDocumentPerformance />,

  // ]
  const serviceData = [
    {
      name:"Oracle Fusion Cloud",
      img:  <BsCloudFill />,
      image:  Logo1,
      para1:{
        head:"Unlock the Power of Oracle Fusion Cloud",
        para:"At VSDL Technologies, we harness the capabilities of Oracle Fusion Cloud to help businesses streamline their operations and enhance productivity. Oracle Fusion Cloud offers a comprehensive suite of applications that integrate finance, HR, supply chain, and customer experience, allowing for a unified approach to business management."
      },
      para2:{
        head:"What is Oracle Fusion Cloud?",
        para:"Oracle Fusion Cloud is a cloud-based platform that provides organizations with a flexible and scalable solution to manage core business processes. By leveraging advanced analytics and automation, it empowers businesses to make informed decisions and improve efficiency."
      },
      para3:{
        head:"Our Approach to Oracle Fusion Cloud",
        para:[
          {
            Shead: "Needs Assessment:",
            Desc: " We analyze your existing systems and workflows to identify how Oracle Fusion Cloud can best meet your needs."
          },{
            Shead:"Implementation:",
            Desc: " Our experts ensure a smooth implementation process, integrating Oracle applications with your current infrastructure while minimizing disruption.",
          },{
            Shead: " Customization: ",
            Desc: " We tailor the solution to align with your specific business requirements, enhancing functionality and user experience.",
          },{
            Shead:"Training and Support:",
            Desc:" We provide comprehensive training and ongoing support to ensure your team maximizes the benefits of Oracle Fusion Cloud.",
          },{
            Shead:"Continuous Optimization: ",
            Desc:" We help you monitor and refine your use of Oracle applications to adapt to changing business needs."
          }
        ]
      },
      para4:{
        head:"Why Choose VSDL Technologies?",
        para:" Our team has extensive experience in Oracle Fusion Cloud implementations across various industries. We prioritize understanding your unique challenges and goals, ensuring a customized approach that drives tangible results. By partnering with us, you gain a dedicated ally committed to your success."
      }
    },
    {
      name:"Salesforce",
      img:  <FaSalesforce />,
      image:  Logo2,
      para1:{
        head:"Elevate Your Business with Salesforce",
        para:" Salesforce is a leading customer relationship management (CRM) platform that transforms how businesses interact with their customers. At VSDL Technologies, we specialize in harnessing Salesforce to enhance customer engagement and drive sales growth."
      },
      para2:{
        head:"What is Salesforce?",
        para:" Salesforce is a cloud-based CRM solution that offers tools for sales, marketing, customer service, and analytics, enabling organizations to build stronger customer relationships and enhance operational efficiency."
      },
      para3:{
        head:"Our Approach to Salesforce",
        para:[
          {
            Shead: "Discovery and Planning:",
            Desc:" We start by understanding your business goals and customer journey to tailor the Salesforce implementation."
          },{
            Shead:"Implementation:",
            Desc:" Our team guides you through the implementation process, ensuring seamless integration with existing systems.",
          },{
            Shead:"Customization: ",
            Desc:" We customize Salesforce to meet your unique requirements, optimizing workflows and user experiences.",
          },{
            Shead:"Training and Onboarding:",
            Desc:" We provide training for your team, ensuring they are equipped to utilize Salesforce effectively.",
          },{
            Shead:"Performance Monitoring:  ",
            Desc:" We offer ongoing support to assess performance and identify opportunities for improvement."
          }
        ]
      },
      para4:{
        head:"Why Choose VSDL Technologies?",
        para:" With a proven track record in Salesforce implementations, our team brings deep expertise to ensure your CRM solution is fully optimized for your business needs. We focus on creating solutions that not only enhance customer engagement but also drive measurable business growth."
      }
    },
    {
      name:"Workday",
      img:  <GrWorkshop />,
      image:  Logo3,
      para1:{
        head:"Optimize HR Processes with Workday",
        para:" VSDL Technologies partners with organizations to implement Workday, a leading human capital management solution, designed to streamline HR processes and enhance employee experiences."
      },
      para2:{
        head:"What is Workday?",
        para:" Workday is a cloud-based platform that combines human resources, finance, and planning into a single system, enabling organizations to manage their workforce efficiently and effectively."
      },
      para3:{
        head:"Our Approach to Workday",
        para:[
          {
            Shead: "Needs Analysis:",
            Desc:" We conduct an in-depth analysis of your HR processes to determine how Workday can best support your goals."
          },{
            Shead:"Implementation:",
            Desc:" Our team ensures a smooth implementation process, aligning Workday with your organizational needs.",
          },{
            Shead:"Integration: ",
            Desc:" We integrate Workday with your existing systems to create a cohesive HR management ecosystem.",
          },{
            Shead:"Training and Support:",
            Desc:" We provide comprehensive training to your HR team, ensuring they can leverage Workday’s features effectively.",
          },{
            Shead:"Continuous Improvement:",
            Desc:" We assist you in optimizing your use of Workday, adapting to new HR trends and needs."
          }
        ]
      },
      para4:{
        head:"Why Choose VSDL Technologies?",
        para:" Our expertise in Workday implementations allows us to provide tailored solutions that enhance your HR capabilities. We are dedicated to ensuring your HR team is equipped to drive employee engagement and organizational success."
      }
    },
    {
      name:"Data Engineering",
      img: <MdDataExploration />,
      image:  Logo4,
      para1:{
        head:"Enhance Data Strategy with Data Engineering",
        para:" In today's data-driven world, VSDL Technologies offers expert data engineering services to help businesses harness the power of their data for strategic decision-making."
      },
      para2:{
        head:"What is Data Engineering?",
        para:" Data engineering involves the design and construction of systems and infrastructure for collecting, storing, and analyzing data. It enables organizations to turn raw data into actionable insights."
      },
      para3:{
        head:"Our Approach to Data Engineering",
        para:[
          {
            Shead: "Assessment and Strategy Development:",
            Desc:" We evaluate your data needs and create a strategic plan tailored to your objectives."
          },{
            Shead:"Architecture Design:",
            Desc:" Our experts design a robust data architecture that ensures scalability and efficiency.",
          },{
            Shead:"Data Integration:",
            Desc:" We implement systems for seamless data integration from various sources, ensuring accuracy and consistency.",
          },{
            Shead:"Data Pipeline Development:",
            Desc:" We build reliable data pipelines for real-time data processing and analytics.",
          },{
            Shead:"Ongoing Support: ",
            Desc:" We provide ongoing monitoring and optimization of your data systems to adapt to evolving needs."
          }
        ]
      },
      para4:{
        head:"Why Choose VSDL Technologies?",
        para:" Our data engineering team combines technical expertise with industry insights to create robust data solutions tailored to your specific requirements. We focus on empowering your organization with the data it needs to drive strategic growth and innovation."
      }
    },
    {
      name:"Java Full Stack",
      img: <RiJavaLine />,
      image:  Logo5,
      para1:{
        head:"Full-Stack Development with Java",
        para:" VSDL Technologies offers comprehensive Java Full Stack development services, enabling businesses to build dynamic and robust web applications that meet modern user demands."
      },
      para2:{
        head:"What is Java Full Stack Development?",
        para:" Java Full Stack development involves both front-end and back-end development using Java technologies, allowing developers to create complete web applications that are scalable and maintainable."
      },
      para3:{
        head:"Our Approach to Java Full Stack Development",
        para:[
          {
            Shead: "Requirements Gathering:",
            Desc:" We work closely with you to understand your application requirements and objectives."
          },{
            Shead:"Architecture Planning:",
            Desc:" Our team designs the architecture of your application, ensuring a strong foundation for future scalability.",
          },{
            Shead:"Front-End Development:",
            Desc:" We develop engaging and responsive user interfaces that enhance user experience.",
          },{
            Shead:"Back-End Development: ",
            Desc:" Our experts build robust server-side logic and databases to support your application’s functionality.",
          },{
            Shead:"Testing and Deployment:",
            Desc:" We conduct thorough testing to ensure quality and reliability before deploying your application."
          }
        ]
      },
      para4:{
        head:"Why Choose VSDL Technologies?",
        para:" With a team of experienced developers, we bring both creativity and technical expertise to your projects. Our focus on quality, scalability, and user experience ensures that your Java applications not only meet current demands but are also future-ready."
      }
    },
    {
      name:"ServiceNow",
      img:  <GrServices />,
      image:  Logo6,
      para1:{
        head:"Streamline IT Services with ServiceNow",
        para:" VSDL Technologies utilizes ServiceNow to help organizations streamline their IT service management (ITSM) processes, improving efficiency and service delivery."
      },
      para2:{
        head:"What is ServiceNow?",
        para:" ServiceNow is a cloud-based platform that provides IT service management solutions, enabling organizations to automate and optimize their IT operations."
      },
      para3:{
        head:"Our Approach to ServiceNow",
        para:[
          {
            Shead: "Assessment:",
            Desc:" We analyze your current IT processes to identify areas for improvement using ServiceNow."
          },{
            Shead:"Implementation:",
            Desc:" Our team ensures a smooth implementation of ServiceNow, integrating it with your existing systems.",
          },{
            Shead:"Customization:",
            Desc:" We tailor the platform to align with your organizational needs, enhancing user experience and efficiency.",
          },{
            Shead:"Training and Support: ",
            Desc:" We provide training for your IT team, ensuring they can effectively utilize ServiceNow’s features.",
          },{
            Shead:"Continuous Improvement:",
            Desc:" We offer ongoing support to refine your use of ServiceNow and adapt to evolving IT needs."
          }
        ]
      },
      para4:{
        head:"Why Choose VSDL Technologies?",
        para:" Our extensive experience with ServiceNow implementations enables us to deliver solutions that maximize the platform's capabilities. We are committed to enhancing your IT service delivery and ensuring your organization stays agile and responsive in a dynamic environment."
      }
    },
    {
      name: "Digital Transformation",
      img:  <SiWesterndigital />,
      image: Logo1,
      para1: {
        head: "Unlock the Future of Business with Comprehensive Digital Transformation",
        para: " In the rapidly evolving business landscape, digital transformation is no longer an option—it’s a necessity. At VSDL Technologies, we understand that embracing digital transformation is key to staying competitive and achieving long-term success. Our digital transformation services are designed to help businesses like yours navigate this complex journey, ensuring you leverage the latest technologies to enhance your operations, improve customer experiences, and drive innovation",
      },
      para2: {
        head: "What is Digital Transformation?",
        para: " Digital transformation involves integrating digital technology into all areas of a business, fundamentally changing how you operate and deliver value to customers. It goes beyond just adopting new technologies; it requires a shift in mindset and processes to create a more agile, data-driven, and customer-focused organization",
      },
      para3: {
        head: "Our Approach to Digital Transformation",
        para: [
          {
            Shead: "Assessment and Strategy Development:",
            Desc: " We start by conducting a thorough assessment of your current systems, processes, and goals. This helps us identify areas that can benefit from digital transformation and develop a strategic roadmap tailored to your specific needs.",
          },
          {
            Shead: "Technology Integration:",
            Desc: " We help you choose and implement the right technologies that align with your business objectives. This could include cloud solutions, enterprise software, data analytics tools, and more. Our team ensures that these technologies are integrated seamlessly into your existing infrastructure.",
          },
          {
            Shead: "Process Optimization:",
            Desc: " Digital transformation is about more than just technology—it’s also about improving processes. We analyze your workflows and identify opportunities for automation and optimization to enhance efficiency and reduce costs.",
          },
          {
            Shead: "Change Management:",
            Desc: " Successful digital transformation requires managing change effectively. We provide support in change management to ensure that your team is prepared for new technologies and processes, fostering a culture of innovation and continuous improvement.",
          },
          {
            Shead: "Continuous Improvement:",
            Desc: " The digital landscape is constantly evolving, and so should your transformation strategy. We offer ongoing support and monitoring to ensure that your digital transformation efforts continue to deliver value and adapt to new developments.",
          },
        ],
      },
      para4: {
        head: "Why Choose VSDL Technologies?",
        para: " Our team of experts has extensive experience in driving digital transformation across various industries. We bring a deep understanding of the latest technologies and best practices, combined with a commitment to delivering solutions that meet your unique business needs. By partnering with us, you gain a trusted advisor who will guide you through every stage of the digital transformation journey, ensuring you achieve your strategic objectives and remain competitive in an increasingly digital world.",
      },
    },

    {
      name: "Cloud Service (AWS, Azure and GCP)",
      img:  <AiOutlineCloudServer />,
      image: Logo2,
      para1: {
        head: "Build a Reliable, Scalable, and Secure IT Foundation with Industry-Leading Cloud Providers",
        para: " Maximize your infrastructure’s potential with VSDL Technologies' expert Infrastructure and Cloud Services. Leveraging top cloud platforms like Microsoft Azure, Amazon Web Services (AWS), and Google Cloud Platform (GCP), we help your business build a resilient IT backbone that scales effortlessly, improves performance, and boosts security.",
      },
      para2: {
        head: "What are Infrastructure and Cloud Services?",
        para: " Our infrastructure and cloud services include everything from designing and managing cloud architecture to optimizing your systems for peak efficiency. By tapping into the advanced capabilities of Azure, AWS, and GCP, we empower your business with agile and cost-effective cloud solutions tailored to meet your specific goals.",
      },
      para3: {
        head: "Our Approach to Infrastructure and Cloud Services",
        para: [
          {
            Shead: "Cloud Assessment and Strategy:",
            Desc: " We begin with a detailed analysis of your infrastructure needs, recommending the ideal cloud solutions to match your business objectives.",
          },
          {
            Shead: "Deployment and Integration: ",
            Desc: " From planning to execution, our team ensures a smooth transition, handling deployment, setup, and integration with minimal disruption.",
          },
          
          {
            Shead: "Optimization and Management:",
            Desc: " Our services include proactive monitoring, regular updates, and fine-tuning, ensuring your infrastructure runs efficiently, securely, and cost-effectively.",
          },
         
        ],
      },
      para4: {
        head: "Why Choose VSDL Technologies?",
        para: " With expertise in Azure, AWS, and GCP, VSDL Technologies delivers customized cloud solutions that support growth, improve performance, and safeguard your data. Partner with us to leverage cloud technology effectively and build a scalable, future-ready infrastructure.",
      },
    },

     {
      name: "Staff Augmentation Services",
      img: <MdOutlineHandyman />,
      image: Logo3,
      para1: {
        head: "Expand Your Team with Top Talent—Fast and Flexible Staffing Solutions",
        para: " When projects demand specialized expertise or rapid scaling, VSDL Technologies' Staff Augmentation Services offer the agility and talent you need to succeed. We connect you with skilled professionals who integrate seamlessly with your in-house team, helping you fill skills gaps, enhance productivity, and drive your projects forward without long-term commitments.",
      },
      para2: {
        head: "What is Staff Augmentation?",
        para: " Staff augmentation is a flexible outsourcing model that enables you to bring in additional resources on a short- or long-term basis. Whether you need specific skills or extra manpower for critical projects, we provide access to a pool of highly qualified professionals ready to meet your unique requirements."
      },
      para3: {
        head: "Our Approach to Staff Augmentation",
        para: [
          {
            Shead: "Talent Matching:",
            Desc: " We assess your needs to identify candidates with the exact skills and expertise required for your project.",
          },
          {
            Shead: "Onboarding and Integration:",
            Desc: " Our team ensures a smooth onboarding process, integrating our professionals into your workflows and culture quickly and effectively.",
          },
          {
            Shead: "Continuous Support:",
            Desc: " With ongoing communication and support, we ensure our professionals meet your expectations, keeping projects on track and aligned with your goals.",
          },
        ],
      },
      para4: {
        head: "Why Choose VSDL Technologies?",
        para: " With a deep talent network and industry-specific expertise, VSDL Technologies offers flexible and fast access to top professionals who are ready to make an impact from day one. Whether you need IT experts, project managers, or developers, we provide a cost-effective solution that scales with your business needs.",
      },
    },

    // {
    //   name: "Cloud (Azure, AWS, GCP)",
    //   image: Logo3,
    //   para1: {
    //     head: "Leverage Leading Cloud Platforms for Scalable and Flexible Solutions",
    //     para: "Cloud computing has revolutionized the way businesses operate, offering unprecedented scalability, flexibility, and cost efficiency. At VSDL Technologies, we specialize in providing cloud solutions across major platforms including Microsoft Azure, Amazon Web Services (AWS), and Google Cloud Platform (GCP). Our expertise ensures that you can harness the full potential of these cloud services to drive innovation and achieve your business objectives.",
    //   },
    //   para2: {
    //     head: "Exploring Cloud Platforms",
    //     para: [
    //       {
    //         Shead: "Microsoft Azure:",
    //         Desc: " Known for its comprehensive suite of cloud services, Azure offers everything from virtual machines and storage to advanced analytics and artificial intelligence. We help you leverage Azure’s capabilities to build, deploy, and manage applications efficiently.",
    //       },
    //       {
    //         Shead: "Amazon Web Services (AWS):",
    //         Desc: " AWS is a leading cloud platform known for its scalability, flexibility, and extensive range of services. Our team helps you navigate AWS to optimize your cloud environment, from setting up infrastructure to deploying applications and managing resources.",
    //       },
    //       {
    //         Shead: "Google Cloud Platform (GCP):",
    //         Desc: " GCP provides powerful tools for data analytics, machine learning, and cloud computing. We assist you in leveraging GCP’s services to drive innovation and enhance your business processes.",
    //       },
    //     ],
    //   },
    //   para3: {
    //     head: "Our Cloud Services",
    //     para: [
    //       {
    //         Shead: "Cloud Strategy and Planning:",
    //         Desc: " We work with you to develop a cloud strategy that aligns with your business goals. This includes assessing your current environment, identifying suitable cloud solutions, and creating a roadmap for migration and implementation.",
    //       },
    //       {
    //         Shead: "Cloud Migration: ",
    //         Desc: " Migrating to the cloud requires careful planning and execution. Our team manages the entire migration process, ensuring a smooth transition with minimal disruption to your operations.",
    //       },
    //       {
    //         Shead: "Cloud Optimization:",
    //         Desc: " Once your cloud environment is set up, we focus on optimizing performance and cost-efficiency. This includes monitoring and managing resources, optimizing cloud storage, and ensuring that your applications run efficiently.",
    //       },
    //       {
    //         Shead: "Cloud Security:",
    //         Desc: " Security is a top priority in the cloud. We implement robust security measures and practices to protect your data and applications from potential threats and ensure compliance with industry standards.",
    //       },
    //       {
    //         Shead: "Ongoing Support: ",
    //         Desc: " Cloud environments require continuous management and support. We provide ongoing maintenance, troubleshooting, and support to ensure your cloud infrastructure remains reliable and effective.",
    //       },
    //     ],
    //   },
    //   para4: {
    //     head: "Why Choose VSDL Technologies?",
    //     para: "With our deep expertise in Azure, AWS, and GCP, we provide tailored cloud solutions that meet your specific business needs. Our team is committed to delivering high-quality services that enhance your cloud capabilities and drive your business success. Partner with us to leverage the power of cloud computing and stay ahead in a competitive market.",
    //   },
    // },

    {
      name: "Data Science",
      img: <MdDatasetLinked/>,
      image: Logo4,
      para1: {
        head: "Transform Your Data into Actionable Insights with Expert Data Science Services",
        para: " Data science is at the forefront of modern business intelligence, enabling organizations to derive meaningful insights from complex data sets. At VSDL Technologies, we offer advanced data science services that help you harness the power of your data to make informed decisions, optimize operations, and drive growth.",
      },
      para2: {
        head: "What is Data Science?",
        para: " Data science involves using statistical methods, machine learning algorithms, and data analysis techniques to extract valuable insights from large and complex data sets. It plays a crucial role in understanding trends, predicting future outcomes, and making data-driven decisions.",
      },
      para3: {
        head: "Our Data Science Services",
        para: [
          {
            Shead: "Data Analysis and Modeling: ",
            Desc: " We apply advanced statistical methods and machine learning algorithms to analyze your data and build predictive models. This helps you uncover trends, identify patterns, and make accurate forecasts based on data-driven insights.",
          },
          {
            Shead: "Data Visualization:",
            Desc: " Presenting data in a clear and compelling way is essential for effective decision-making. Our team creates interactive dashboards and visualizations that make it easy to understand complex data and communicate insights to stakeholders.",
          },
          {
            Shead: "Big Data Solutions:",
            Desc: " Managing and analyzing large volumes of data can be challenging. We provide big data solutions that enable you to handle vast amounts of data efficiently, using tools and technologies designed for scalability and performance.",
          },
          {
            Shead: "Custom Data Solutions:",
            Desc: " Every business has unique data needs. We develop custom data science solutions tailored to your specific requirements, whether you need advanced analytics, data mining, or algorithm development.",
          },
          {
            Shead: "Data Strategy and Consulting: ",
            Desc: " Developing a data strategy is crucial for leveraging data effectively. We offer consulting services to help you create a data strategy that aligns with your business objectives and ensures you get the most value from your data.",
          },
        ],
      },
      para4: {
        head: "Why Choose VSDL Technologies?",
        para: " Our team of data scientists and analysts brings extensive experience in transforming data into actionable insights. We use the latest technologies and methodologies to deliver solutions that drive business success and enhance decision-making. By partnering with VSDL Technologies, you gain access to expert data science services that unlock the full potential of your data.",
      },
    },

    {
      name: "Artificial Intelligence",
      img: <GiArtificialIntelligence />,
      image: Logo5,
      para1: {
        head: "Transform Your Business with Cutting-Edge Artificial Intelligence Solutions",
        para: " Artificial Intelligence (AI) is revolutionizing industries by automating processes, enhancing decision-making, and creating new opportunities for innovation. At VSDL Technologies, we offer advanced AI solutions that help you leverage the power of AI to drive business success, improve efficiency, and stay ahead in a competitive market.",
      },
      para2: {
        head: "What is Artificial Intelligence?",
        para: " Artificial Intelligence involves creating systems and technologies that can perform tasks that typically require human intelligence. This includes capabilities such as learning, reasoning, problem-solving, and decision-making. AI encompasses a range of technologies, including machine learning, natural language processing, and computer vision.",
      },
      para3: {
        head: "Our AI Services",
        para: [
          {
            Shead: "Machine Learning:",
            Desc: " We develop machine learning models that enable your systems to learn from data and make predictions or decisions without explicit programming. Our solutions include supervised learning, unsupervised learning, and reinforcement learning.",
          },
          {
            Shead: "Natural Language Processing (NLP):",
            Desc: " Our NLP services help you analyze and understand human language, enabling applications such as chatbots, sentiment analysis, and language translation. We create NLP solutions that enhance customer interactions and automate text-based tasks.",
          },
          {
            Shead: "Computer Vision:",
            Desc: " We leverage computer vision technologies to enable machines to interpret and analyze visual data. This includes applications such as image recognition, object detection, and video analysis, providing valuable insights from visual information.",
          },
          {
            Shead: "AI Strategy and Consulting:",
            Desc: " Developing a comprehensive AI strategy is crucial for leveraging AI effectively. We offer consulting services to help you identify AI opportunities, define use cases, and develop a roadmap for AI adoption.",
          },
          {
            Shead: "AI Integration:",
            Desc: " Integrating AI technologies into your existing systems can enhance functionality and performance. We assist with the integration of AI solutions, ensuring they work seamlessly with your current infrastructure and applications.",
          },
        ],
      },
      para4: {
        head: "Why Choose VSDL Technologies?",
        para: " Our team of AI experts brings extensive experience in developing and implementing AI solutions that drive business innovation and efficiency. We use the latest technologies and methodologies to deliver AI solutions that meet your specific needs and objectives. By partnering with VSDL Technologies, you gain access to cutting-edge AI capabilities that transform your business and create new opportunities for growth.",
      },
    },

    {
      name: "Cyber Security",
      img:  <GiCyberEye />,
      image: Logo6,
      para1: {
        head: "Protect Your Business with Comprehensive Cyber Security Solutions",
        para: " In an increasingly digital world, protecting your business from cyber threats is more important than ever. At VSDL Technologies, we provide robust cyber security solutions designed to safeguard your systems, data, and networks from potential breaches and ensure your business remains secure and resilient.",
      },
      para2: {
        head: "Understanding Cyber Security",
        para: " Cyber security involves protecting your digital assets from unauthorized access, attacks, and damage. This includes implementing measures to prevent, detect, and respond to cyber threats, ensuring the confidentiality, integrity, and availability of your information.",
      },
      para3: {
        head: "Our Cyber Security Services",
        para: [
          {
            Shead: "Risk Assessment:",
            Desc: " We conduct thorough risk assessments to identify potential vulnerabilities and threats to your systems. This helps us develop a comprehensive security strategy tailored to your specific needs.",
          },
          {
            Shead: "Threat Detection and Response:",
            Desc: " Our threat detection and response services include monitoring your systems for suspicious activity, detecting potential threats, and responding quickly to mitigate risks and prevent damage.",
          },
          {
            Shead: "Security Compliance:",
            Desc: " Ensuring compliance with industry standards and regulations is crucial for maintaining security and protecting sensitive information. We help you navigate compliance requirements and implement measures to meet industry standards.",
          },
          {
            Shead: "Incident Response:",
            Desc: " In the event of a security breach, our incident response services provide immediate support to contain, investigate, and resolve the issue. We work to minimize the impact of the breach and restore normal operations.",
          },
          {
            Shead: "Security Awareness Training:",
            Desc: " Educating your employees about cyber security best practices is essential for preventing security breaches. We provide training programs to raise awareness and ensure that your team understands how to protect your digital assets.",
          },
        ],
      },
      para4: {
        head: "Why Choose VSDL Technologies?",
        para: " Our cyber security experts bring extensive experience in protecting businesses from a wide range of cyber threats. We use advanced technologies and best practices to deliver security solutions that safeguard your systems and data. By partnering with VSDL Technologies, you gain access to comprehensive cyber security services that ensure your business remains secure and resilient..",
      },
    },

    {
      name: "Data Analytics",
      img:  <TbDatabaseStar />,
      image: Logo1,
      para1: {
        head: "Gain Valuable Insights with Comprehensive Data Analytics Services",
        para: " Data analytics is essential for understanding your business performance and making informed decisions. At VSDL Technologies, we offer a range of data analytics services designed to help you interpret and leverage your data effectively, providing you with the insights needed to drive growth and improve operations.",
      },
      para2: {
        head: "What is Data Analytics?",
        para: " Data analytics involves examining and interpreting data to uncover patterns, trends, and insights that can inform business decisions. It encompasses various techniques and tools used to analyze data and extract meaningful information that supports strategic planning and operational improvements.",
      },
      para3: {
        head: "Our Data Analytics Services",
        para: [
          {
            Shead: "Descriptive Analytics:",
            Desc: " We provide descriptive analytics services that summarize historical data and provide insights into past performance. This helps you understand what has happened in your business and identify key trends.",
          },
          {
            Shead: "Diagnostic Analytics:",
            Desc: " Our diagnostic analytics services go beyond descriptive analysis to uncover the reasons behind past events. We analyze data to identify root causes and understand factors that have influenced business outcomes.",
          },
          {
            Shead: "Predictive Analytics:",
            Desc: " We offer predictive analytics services that forecast future trends and outcomes using advanced statistical models and machine learning algorithms. This helps you anticipate changes and make proactive decisions.",
          },
          {
            Shead: "Prescriptive Analytics:",
            Desc: " We provide prescriptive analytics services that recommend actions based on data insights. This helps you optimize processes and make informed decisions that drive better business outcomes.",
          },
          {
            Shead: "Data Integration and Management:",
            Desc: " Effective data analytics requires reliable data management. We assist with integrating data from various sources, ensuring data quality and consistency, and managing data to support accurate analysis.",
          },
        ],
      },
      para4: {
        head: "Why Choose VSDL Technologies?",
        para: " With our expertise in data analytics, we provide solutions that turn your data into actionable insights. Our team uses advanced tools and techniques to deliver accurate, reliable, and meaningful analytics that support your business goals. By partnering with VSDL Technologies, you gain access to data analytics services that drive strategic decision-making and operational excellence",
      },
    },

    // {
    //   name: "Microservices",
    //   image: Logo2,
    //   para1: {
    //     head: "Build Flexible and Scalable Applications with Microservices Architecture",
    //     para: "Microservices architecture is a modern approach to software development that offers flexibility, scalability, and resilience. At VSDL Technologies, we specialize in designing and implementing microservices solutions that enable you to build and deploy applications efficiently, adapt to changing business needs, and enhance overall performance.",
    //   },
    //   para2: {
    //     head: "What is Microservices Architecture?",
    //     para: "Microservices architecture breaks down complex applications into smaller, independent services that can be developed, deployed, and scaled individually. This approach allows for greater flexibility, easier maintenance, and faster deployment compared to traditional monolithic architectures.",
    //   },
    //   para3: {
    //     head: "Our Microservices Services",
    //     para: [
    //       {
    //         Shead: "Microservices Design:",
    //         Desc: " We design microservices architectures that align with your business requirements. Our approach focuses on creating modular, scalable, and loosely coupled services that enhance application flexibility and performance.",
    //       },
    //       {
    //         Shead: "Development and Integration:",
    //         Desc: " Our team develops microservices that integrate seamlessly with your existing systems and applications. We ensure that each service functions independently while maintaining coherence across the entire application.",
    //       },
    //       {
    //         Shead: "Deployment and Orchestration:",
    //         Desc: " We manage the deployment and orchestration of microservices, ensuring that services are deployed efficiently and can scale based on demand. We use containerization and orchestration tools like Docker and Kubernetes to streamline deployment.",
    //       },
    //       {
    //         Shead: "Monitoring and Maintenance:",
    //         Desc: " Effective monitoring and maintenance are crucial for microservices applications. We provide ongoing support to monitor performance, identify issues, and ensure that your microservices architecture remains robust and reliable.",
    //       },
    //       {
    //         Shead: "Migration to Microservices:",
    //         Desc: " If you’re transitioning from a monolithic architecture to microservices, we assist with the migration process, ensuring a smooth transition with minimal disruption to your operations.",
    //       },
    //     ],
    //   },
    //   para4: {
    //     head: "Why Choose VSDL Technologies?",
    //     para: "Our expertise in microservices architecture enables us to deliver solutions that enhance application flexibility, scalability, and performance. We work closely with you to design and implement microservices that meet your specific needs and drive business success. Partner with VSDL Technologies to build modern, efficient applications that adapt to your evolving business requirements.",
    //   },
    // },

    {
      name: "Design and Development",
      img:  <MdOutlineDesignServices />,
      image: Logo3,
      para1: {
        head: "Create Exceptional Digital Experiences with Expert Design and Development Services",
        para: " In today's digital world, creating a strong online presence and delivering exceptional user experiences are essential for business success. At VSDL Technologies, we offer design and development services that combine creativity with technical expertise to build engaging, user-friendly digital solutions that meet your business objectives.",
      },
      para2: {
        head: "What is Design and Development?",
        para: " Design and development involve creating digital products such as websites, mobile apps, and software applications. This process includes everything from user interface (UI) and user experience (UX) design to front-end and back-end development.",
      },
      para3: {
        head: "Our Design and Development Services",
        para: [
          {
            Shead: "UI/UX Design:",
            Desc: " We design intuitive and visually appealing user interfaces that enhance the user experience. Our approach focuses on creating designs that are user-centric, ensuring that your digital products are easy to use and navigate.",
          },
          {
            Shead: "Web Development:",
            Desc: " Our web development services include building responsive and dynamic websites that meet your business needs. We use the latest technologies and best practices to create websites that are functional, secure, and optimized for performance.",
          },
          {
            Shead: "Mobile App Development:",
            Desc: " We develop mobile applications for iOS and Android platforms that deliver seamless and engaging experiences. Our team handles everything from concept and design to development and deployment, ensuring that your app meets your business goals.",
          },
          {
            Shead: "Custom Software Development:",
            Desc: " If you need a bespoke software solution, we provide custom development services tailored to your specific requirements. We build software that addresses your unique challenges and integrates with your existing systems.",
          },
          {
            Shead: "Maintenance and Support:",
            Desc: " Ongoing maintenance and support are crucial for ensuring the continued success of your digital products. We offer support services to address any issues, perform updates, and ensure that your digital solutions remain effective and up-to-date.",
          },
        ],
      },
      para4: {
        head: "Why VSDL Technologies?",
        para: " Our design and development team combines creativity with technical expertise to deliver digital solutions that drive business success. We work closely with you to understand your needs and create products that enhance user experiences and achieve your business objectives. Partner with VSDL Technologies to build exceptional digital experiences that set you apart from the competition.",
      },
    },

    {
      name: "Enterprise Application",
      img:  <SiEnterprisedb />,
      image: Logo4,
      para1: {
        head: "Streamline Your Business Operations with Tailored Enterprise Applications",
        para: " Enterprise applications are crucial for managing complex business processes and enhancing operational efficiency. At VSDL Technologies, we specialize in developing and implementing enterprise applications that are tailored to meet the unique needs of large organizations, driving productivity and supporting strategic initiatives.",
      },
      para2: {
        head: "What are Enterprise Applications?",
        para: " Enterprise applications are software solutions designed to address the needs of large organizations, including functions such as enterprise resource planning (ERP), customer relationship management (CRM), and supply chain management. These applications help streamline processes, improve collaboration, and manage vast amounts of data.",
      },
      para3: {
        head: "Our Enterprise Application Services",
        para: [
          {
            Shead: "Application Development:",
            Desc: " We develop custom enterprise applications that align with your business requirements and objectives. Our solutions are designed to integrate seamlessly with your existing systems and enhance your operational capabilities.",
          },
          {
            Shead: "ERP Solutions:",
            Desc: " Our ERP solutions provide a comprehensive platform for managing various business processes, including finance, human resources, and supply chain management. We tailor ERP systems to meet your specific needs and improve overall efficiency.",
          },
          {
            Shead: "CRM Solutions:",
            Desc: " We offer CRM solutions that help you manage customer relationships, track interactions, and analyze customer data. Our CRM systems are designed to enhance customer engagement and support sales and marketing efforts.",
          },
          {
            Shead: "Integration Services:",
            Desc: " Integrating enterprise applications with your existing systems is crucial for ensuring seamless operations. We handle the integration process, ensuring that your applications work together efficiently and effectively.",
          },
          {
            Shead: "Support and Maintenance: ",
            Desc: " Ongoing support and maintenance are essential for ensuring the continued success of your enterprise applications. We provide support services to address any issues, perform updates, and ensure that your applications remain reliable and effective.",
          },
        ],
      },
      para4: {
        head: "Why VSDL Technologies?",
        para: " Our expertise in enterprise application development enables us to deliver solutions that streamline your business processes and drive operational excellence. We work closely with you to understand your needs and create applications that support your strategic goals. Partner with VSDL Technologies to enhance your enterprise capabilities and achieve business success.",
      },
    },

    {
      name: "DevOps",
      img: <SiAzuredevops />,
      image: Logo5,
      para1: {
        head: "Accelerate Your Software Development with Comprehensive DevOps Services",
        para: " DevOps is a methodology that combines development and operations to improve collaboration, automate processes, and enhance software delivery. At VSDL Technologies, we provide DevOps services designed to streamline your software development lifecycle, enabling you to deliver high-quality software rapidly and efficiently.",
      },
      para2: {
        head: "What is DevOps?",
        para: " DevOps is a set of practices and tools that aims to improve collaboration between development and operations teams, automate workflows, and enhance the overall software delivery process. It focuses on continuous integration, continuous delivery (CI/CD), and monitoring to drive efficiency and quality.",
      },
      para3: {
        head: "Our DevOps Services",
        para: [
          {
            Shead: "CI/CD Implementation:",
            Desc: " We implement continuous integration and continuous delivery (CI/CD) pipelines to automate the process of building, testing, and deploying software. This enables faster releases, higher quality, and more reliable software.",
          },
          {
            Shead: "Infrastructure Automation:",
            Desc: " Automation is a key component of DevOps. We use tools like Terraform and Ansible to automate the provisioning and management of infrastructure, reducing manual effort and improving consistency.",
          },
          {
            Shead: "Monitoring and Performance:",
            Desc: " Effective monitoring and performance management are crucial for ensuring the reliability of your software. We set up monitoring systems to track application performance, identify issues, and optimize performance.",
          },
          {
            Shead: "Collaboration and Culture: ",
            Desc: " DevOps promotes collaboration between development and operations teams. We help foster this culture by implementing best practices, facilitating communication, and aligning teams with common goals.",
          },
          {
            Shead: "Security Integration:",
            Desc: " Integrating security into the DevOps process is essential for protecting your software and data. We incorporate security practices and tools into the DevOps pipeline to ensure security is addressed throughout the development lifecycle",
          },
        ],
      },
      para4: {
        head: "Why VSDL Technologies?",
        para: " Our DevOps expertise enables us to deliver solutions that enhance software development and delivery processes. We work closely with you to implement DevOps practices that improve collaboration, automation, and performance. Partner with VSDL Technologies to accelerate your software development and achieve operational excellence.",
      },
    },
  ];
  let { index ,Scrool ,setIndex,setScrool} = useContext(dataShare); // context Api
  const navigate = useNavigate()
    const [data, setData] = useState(serviceData[index]);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const { serviceName } = useParams();
  const initialIndex = serviceName
    ? serviceData.findIndex(
        (service) =>
          service.name.toLocaleLowerCase() ===
          serviceName.replace(/\-/g, " ").toLocaleLowerCase()
      )
    : 0;

  // useEffect(() => {
  //   // Check if the page has been reloaded before
  //   if (!sessionStorage.getItem("reloaded")) {
  //     sessionStorage.setItem("reloaded", "true");
  //     window.location.reload();
  //   }
  // }, []);

  useEffect(() => {
    setData(serviceData[initialIndex]);
  setSelectedIdx(initialIndex);
  return ()=>{
   setData(serviceData[0]) 
   setIndex(0)
  }
}, [initialIndex]);



  const handleClickNavigateContact = () => {
    navigate("/Contact");
  };

  // const NavigateUrl=(serviceName) => {
  //   const url = `/services/${serviceName.toLowerCase().replace(/\s+/g, '-')}`;
  //   navigate(url);
  // }

  const NavigateUrl = (serviceName) => {
    const formattedServiceName = serviceName
      ?.toLowerCase()
      ?.replace(/\s+/g, "-");
    const url = `/services/${formattedServiceName}`;
    navigate(url);
  };

  const selectFunction = (obj, idx) => {
    setData(obj);
    setSelectedIdx(idx);
  };
  useEffect(() => {
    setData(serviceData[index]);
    setSelectedIdx(index);
  }, []);

  return (
    <div>
      <NavBar title={"Better services just for you"} image={Image12} />
      <div className="backgroundCol">
        <div className="service-container">
          <div className="service-container-content">
            <h1 className="rangeOfService">A wide range of services</h1>
            <p className="rangeOfService2">
              We value our highly skilled engineers, enabling them to provide
              advocacy and streamlined engineering solutions in a manner your
              company deserves.
            </p>
          </div>
          <div className="colorCircle"></div>
          <div className="colorCircle2"></div>
          <div className="service-middleCard">
            <div className="service-sidenav">
              <ul className="service-list">
                {serviceData.map((each, idx) => (
                  <li
                    onClick={
                      () => {
                        // if(serviceName){
                        selectFunction(each, idx);
                        NavigateUrl(each.name);
                      }
                      // }
                    }
                    className={`items ${selectedIdx === idx ? "selected" : ""}`}
                    key={idx}
                  >
                    <span className="service_icons">{each.img}</span>
                    {each.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="wightCard">
              <div className="image-and-content">
                <div className="div1Image">
                  <img src={data?.image} alt="loading" />
                  <h1>{data?.name}</h1>
                </div>
                <div className="div2Content">
                  
                  <div className="Content_Div">
                    <h4>{data?.para1?.head}</h4>
                    <p>{data?.para1.para}</p>
                    <h4>{data?.para2.head}</h4>
                    {Array.isArray(data?.para2.para) ? (
                      data?.para2?.para.map((obj, idx) => {
                        return (
                          <div key={idx} className="Span_div">
                            <span className="SubHeading">
                              {idx + 1}. {obj.Shead}
                            </span> 
                            <span> {obj.Desc}</span>
                          </div>
                        );
                      })
                    ) : (
                      <p>{data?.para2.para}</p>
                    )}
                    <h4>{data?.para3.head}</h4>
                    {data?.para3?.para.map((object, index) => {
                      return (
                        <div key={index} className="Span_div">
                          <span className="SubHeading">
                            {index + 1}. {object.Shead}
                          </span>
                          <span className="Content_div_Span">{object.Desc}</span>
                        </div>
                      );
                    })}
                    <h4>{data?.para4?.head}</h4>
                    <p>{data?.para4?.para}</p>
                  </div>
                </div>
              </div>
              <div className="get-a-quote" onClick={handleClickNavigateContact}>
                <h5>Get a quote</h5>
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
        <div className="service-footer">
          <PreFooter />
          <Footer />
        </div>
      </div>
      <Scrooling scrollX={Scrool.X} scrollY={Scrool.Y} />
    </div>
  );
}

export default Services;
