import "./NavBar.css";
import { Link } from "react-router-dom";
import VSDLLogo from "../../Assets/Home/VSDL Logo.png";
import Scrooling from "../ScrollToTop/Scroll";
import { FaBars } from "react-icons/fa";
import { useEffect, useState } from "react";
import { ImCross } from "react-icons/im";

function NavBar({ title, image, children }) {
  const [active, setactive] = useState(false);

  const handleClickNav = () => {
    setactive(!active);
  };
 const [isScrolled, setIsScrolled] = useState(false);

 useEffect(() => {
   const handleScroll = () => {
     window.scrollY == 0 ? setIsScrolled(false) : setIsScrolled(true);
   };

   window.addEventListener("scroll", handleScroll);

   // Prevent browser from restoring scroll position
  //  if ("scrollRestoration" in history) {
  //    history.scrollRestoration = "manual";
  //  }

   return () => {
     window.removeEventListener("scroll", handleScroll);
   };
 }, []);
  
   return (
     <div className="Main_Nav">
       <Scrooling />
       <div className="NavImage">
         <img src={image} alt="NavImage"></img>
       </div>
       <nav className="create-navbar">
         <div className={isScrolled ? "navbar" : "navbar_hide"}>
           <div className="CuroselAndImg">
             <Link style={{ textDecoration: "none" }} to="/">
               <img src={VSDLLogo} alt="VsdlLogo"></img>
             </Link>
             {active ? (
               <div>
                 <ImCross className="CrossMark" onClick={handleClickNav} />
               </div>
             ) : (
               <div>
                 <FaBars className="CuroselIcon" onClick={handleClickNav} />
               </div>
             )}
           </div>
           <ul className={`navItems ${active ? "shownavItems" : "noNav"}`}>
             <li>
               <Link to="/" className="Nav_links" onClick={handleClickNav}>
                 Home
               </Link>
             </li>

             <li>
               <Link
                 className="Nav_links nav_who-we-are"
                 to="/WhoWeAre"
                 onClick={handleClickNav}
               >
                 Who We Are
               </Link>
             </li>
             <li>
               <Link
                 to="/Services"
                 className="Nav_links nav_what_we_do"
                 onClick={handleClickNav}
               >
                 What We Do
               </Link>
             </li>
             <li>
               <Link to="/Blogs" className="Nav_links" onClick={handleClickNav}>
                 Blog
               </Link>
             </li>
             <li>
               <Link
                 to="/Careers"
                 className="Nav_links"
                 onClick={handleClickNav}
               >
                 Careers
               </Link>
             </li>
             <li>
               <Link
                 to="/Contact"
                 className="Nav_links"
                 onClick={handleClickNav}
               >
                 Contact
               </Link>
             </li>
           </ul>
         </div>
       </nav>

       <div className="better-service-container">
         <h1 className="better-service">{title}</h1>
         {children}
       </div>
     </div>
   );
}

export default NavBar;
