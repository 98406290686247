import React, { useEffect, useState } from "react";
import "./TermsAndConditions.css";
import Footer from "../Footer/Footer";
import Scrooling from "../ScrollToTop/Scroll";
import VSDL from "../../Assets/Home/VSDL Logo.png";
import { Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";
import NavBar from "../NavBar/NavBar";

const TermsAndConditions = () => {
  const [active, setactive] = useState(false);
  const handleClickNav = () => {
    setactive(!active);
  };
  const handleEmail1 = () => {
    window.location.href = "mailto:hi@vsdltechnologies.com";
  };
  return (
    <div className="termsandcondition-main-container">
      <div className="MH_Nav_Main">
        <div className="Nav_Bar">
          <div className="MH_Nav_Bar">
            <Link style={{ textDecoration: "none" }} to="/">
              <img src={VSDL} alt="Logo" />
            </Link>
            {active ? (
              <div>
                <ImCross className="CrossMark" onClick={handleClickNav} />
              </div>
            ) : (
              <div>
                <FaBars className="CuroselIcon" onClick={handleClickNav} />
              </div>
            )}
          </div>
          <ul className={`Nav_Links ${active ? "shownavLinks" : "noNavLink"}`}>
            <li>
              <Link
                to="/"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Home
              </Link>
            </li>
            <li className="who-we-are-home">
              <Link
                to="/WhoWeAre"
                style={{
                  textDecoration: "none",
                  color: "#DFE3FB",
                }}
                onClick={handleClickNav}
              >
                Who We Are
              </Link>
            </li>
            <li className="what_we_do_home">
              <Link
                to="/Services"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                What We Do
              </Link>
            </li>
            <li>
              <Link
                to="/Blogs"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/Careers"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                to="/Contact"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="termsandcondition-container">
        <h1>Terms And Conditions</h1>
        <p>
          Your acceptance of compliance to these Terms will determine your
          ability to access and use the Service. All users, visitors, and others
          who access or use the website are subject to these terms.
        </p>
        <p>
          You acknowledge that you are responsible for adhering to any
          applicable local laws and that you will be bound by these terms of
          service and all applicable laws and regulations by using this website.
          It is forbidden for you to use or access this site if you disagree
          with any of these terms. The content on this website is shielded by
          relevant trademark and copyright laws.
        </p>
        <p>
          Except as otherwise noted, the content on this website is given to you
          without charge, with no promises or assurances, and is only meant to
          be used for general informational purposes. VSDL has taken every
          precaution to guarantee that the data on this website is accurate as
          of the publication date.
        </p>
        <p>
          VSDL is been the one of all copyrights, trademarks, and other
          intellectual property rights used on this website. Apart from the
          restricted ability to use the website in compliance with these terms,
          you have no additional rights to it. Information and content on this
          website may be printed off or downloaded for personal use. Any portion
          of this website or its contents may not be sold or distributed in any
          way. Without obtaining prior consent from VSDL, you are not
          permitted to use any portion of this website on another website.
        </p>
        <h2>To encourage you and others to use our services.</h2>
        <p>
          Furthermore, we might utilise the data we gather to market to you
          about our services or recommend other deals we have for you to take
          advantage of. We might also use your Personal Information to market
          our services to prospective customers and other users who might be
          similar to you.
        </p>
        <p>
          With the exception of what is specifically stated on this website,
          VSDL is not liable for the messages you send or receive, the
          content of any webpages, or how you use them.
        </p>
        <h2>Links to external web pages</h2>
        <p>
          The content, privacy practices, and policies of any third-party
          websites or services are outside VSDL's control and responsibility.
          Additionally, you understand and agree that VSDL will not be held
          directly or indirectly accountable for any loss or damage resulting
          from using or depending on any of the products, services, or content
          made available on or through any of these websites or services, or
          from claims made in connection with such use or reliance.
        </p>
        <p>
          It is highly recommended that you carefully review the terms and
          conditions as well as privacy policies of any third-party websites or
          services you use.
        </p>
        <h2>Closure</h2>
        <p>
          Without warning or responsibility, we reserve the right to instantly
          discontinue or suspend your use of our service for any reason,
          including but not limited to when you violate the terms.
        </p>
        <p>
          Any clause in the Terms that should, by definition, last until the end
          of time will do so. Examples of such clauses are ownership provisions,
          warranty disclaimers, indemnity, and liability limitations.
        </p>
        <h2>Modifications</h2>
        <p>
          We reserve the right to amend or update these Terms at any moment, in
          our sole discretion. We will make an effort to give at least 30 days'
          notice before any new terms take effect if a revision is significant.
          Our sole discretion will be used to determine what changes are
          considered material.
        </p>
        <p>
          You accept to be bound by the updated terms if you use our website or
          log in after those changes take effect. Please discontinue using the
          Service if you do not agree to the updated terms.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you would like to update, remove, or modify any Personal
          Information that we may have about you, or if you have any concerns
          regarding how we have handled any privacy-related issue, please write
          us an email or letter at: (<span style={{cursor:"pointer"}} onClick={handleEmail1}>hi@vsdltechnologies.com</span>).
        </p>
        <p>7304 W. 130th Street,
        <br />
        Suite #220 Overland park
        <br />
        New York(NY), 10941
        <br/>
        <span style={{cursor:"pointer"}} onClick={handleEmail1}>hi@vsdltechnologies.com</span>
        <br />
        </p>
        <p>
          Thanks for taking the time to learn about VSDL’s Terms and
          conditions, and thanks for trusting us to manage your brand.
        </p>
      </div>
      <Footer />
      <Scrooling />
    </div>
  );
};

export default TermsAndConditions;
