import React, { useContext, useEffect, useRef, useState } from "react";
import NavBar from "../NavBar/NavBar";
import CareerNav from "../../Assets/NavBar/CareerNav.jpg";
import "./Careers.css";
import image1 from "../../Assets/Careers/Rectangle 16.png";
import arrow from "../../Assets/Careers/Arrow 12.png";
import PreFooter from "../PreFooter/PreFooter";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router-dom";
import { dataShare } from "../../App";
import {
  database,
  storage,
  ref,
  set,
  push,
  storageRef,
  uploadBytes,
  getDownloadURL,
  get,
} from "../firebase/firebase";
import { FaArrowRightLong } from "react-icons/fa6";

export default function Careers() {
  const navigate = useNavigate();
  const careerSectionRef = useRef(null);
  const { setScrool } = useContext(dataShare);
  const [blogs, setBlogs] = useState([]);

  console.log(blogs, "blogs");
  useEffect(() => {
    setScrool({ X: 0, Y: 0 });
  }, []);

  const [isModalOpen, setIsModalOpen] = useState(false); // added state variable
  const [isModalOpen2, setIsModalOpen2] = useState(false); // added state
  const [selectedJob, setSelectedJob] = useState(null);

  // const NavigateContact = () => {
  //   navigate("/contact");
  // };
  // const NavigateContact = () => {
  //   careerSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  // };
  const fetchBlogs = async () => {
    const blogRef = ref(database, "formData");
    const snapshot = await get(blogRef);
    if (snapshot.exists()) {
      setBlogs(Object.values(snapshot.val()));
      // setBlogs([...RecentBlogsInfo, Object.values(snapshot.val())])
    }
  };
  useEffect(() => {
    fetchBlogs();
  }, []);

  console.log(blogs, "form ddddd");

  const NavigateContact = () => {
    const offset = 160;
    const sectionPosition =
      careerSectionRef.current.getBoundingClientRect().top + window.scrollY;

    window.scrollTo({
      top: sectionPosition - offset,
      behavior: "smooth",
    });
  };

  const handleApply = () => {
    console.log("handleApply called");
    setIsModalOpen(true); // added function to open modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // added function to close modal
  };

  const handleApply2 = (job) => {
    console.log("handleApply2 called");
    setIsModalOpen2(true);
    setSelectedJob(job);
  };
  const handleCloseModal2 = () => {
    setIsModalOpen2(false);
    setSelectedJob(null);
  };
  const handleEmail = () => {
    window.location.href = "mailto:info@vsdltech.com";
  };
  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isModalOpen]);

  // useEffect(() => {
  //   if (isModalOpen2) {
  //     document.classList.add("modal-open1");
  //   } else {
  //     document.classList.remove("modal-open1");
  //   }
  // });
  return (
    <>
      <NavBar title={"Be a part of our mission"} image={CareerNav}>
        <button onClick={NavigateContact}>Apply now</button>
      </NavBar>
      <div className="career-container">
        <div className="circle-box1 carrer-circle1"></div>
        <div className="circle-box1 carrer-circle2"></div>
        <div className="career-main">
          <h1>Why you should join us?</h1>
          <div className="career-section1">
            <div className="career-section1-content1">
              <div className="career-contents">
                <h3>A culture of care and commitment</h3>
                <p style={{ marginTop: "0.5vw" }}>
                  We're a family, building relationships, open communication,
                  commitment, dedication, serving others, lifting each other
                </p>
              </div>
              <div className="career-contents">
                <h3>Ever-expanding opportunity</h3>
                <p style={{ marginTop: "0.5vw" }}>
                  The sky’s the limit to what’s possible and where you’ll take
                  your career.
                </p>
              </div>
              <div className="career-contents">
                <h3>Lending a hand</h3>
                <p style={{ marginTop: "0.5vw" }}>
                  The communities where we live and work are important to us. We
                  believe in giving back and helping those who may need a hand.
                </p>
              </div>
            </div>

            <div className="career-section1-content2">
              <div className="career-contents1">
                <h3 style={{ marginTop: "-1vw" }}>Balanced benefits</h3>
                <p style={{ marginTop: "1vw" }}>
                  With a comprehensive benefits package that provides
                  flexibility and promotes work/life balance
                </p>
              </div>
              <div className="career-contents1">
                <h3 style={{ marginTop: "0vw" }}>
                  Championing Success Together
                </h3>
                <p style={{ marginTop: "0.5vw" }}>
                  At VSDL we believe in the power of collaboration and teamwork.
                </p>
              </div>
              <div className="career-contents1">
                <h3 style={{ marginTop: "2vw" }}>Be Guided by Experts</h3>
                <p style={{ marginTop: "1vw" }}>
                  Our seasoned professionals will guide and mentor you,
                  providing valuable insights 
                </p>
              </div>
            </div>
            <img src={image1} alt="carrer-imge" />
          </div>

          <div className="career-section2">
            <h1>Open positions</h1>
            <div className="career-section2-main">
              <div
                className="career-section2-main-container"
                ref={careerSectionRef}
              >
                {blogs.length > 0 &&
                  blogs.filter((ele)=>ele.position_status === "Open")
                  .map((ele, ind) => (
                    <div key={ind} className="career-section2-content">
                      <div className="career-section2-content-box">
                        <h4>{ele.position}</h4>
                        <p>{ele.location}</p>
                      </div>
                      <button className="apply-button" onClick={()=>handleApply2(ele)}>
                        Apply now
                      </button>
                    </div>
                  ))}

                {/* <div className="career-section2-content">
                  <div className="career-section2-content-box">
                    <h4>AI Architect</h4>
                    <p>New York, NY</p>
                  </div>
                  <button onClick={handleApply2}>Apply now</button>
                </div>

                <div className="career-section2-content">
                  <div className="career-section2-content-box">
                    <h4>Data Engineer</h4>
                    <p>New York, NY</p>
                  </div>
                  <button className="apply-button" onClick={handleApply2}>
                    Apply now
                  </button>
                </div> */}
              </div>

              <div className="career-section2-side">
                <div>
                  <h3>Nothing for you here? Self- application</h3>
                  {/* <img src={arrow} alt="arrow" onClick={handleApply} /> */}
                  <FaArrowRightLong
                    onClick={handleApply}
                    className="arrow_career"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* {isModalOpen2 && selectedJob &&(
        <div className="application-modal1">
          {blogs.map((each, index) => {
            return (
              <div className="application-modal-content1" key={index}>
                <div className="vsdl-heading-cancel">
                  <button className="close-button1" onClick={handleCloseModal2}>
                    &times;
                  </button>
                  <h1>VSDL heading</h1>
                </div>
                <div className="application-modal1-main">
                  <div className="application-modal1-heading">
                    <h1>VSDL </h1>
                  </div>
                  <div className="application-modal1-date">
                    <h1>Posting Date: </h1>
                    <p>{each.postingDate}</p>
                  </div>
                </div>
                <h2>Position</h2>
                <hr />
                <ul>
                  <li>{each.position}</li>
                </ul>
                <h2>Location</h2>
                <hr />
                <p>{each.location}</p>
                <h2>Duration</h2>
                <hr />
                <p>{each.duration}</p>

                <h2>Job Type</h2>
                <hr />
                <p>{each.job_type}</p>
                <h2>Salary</h2>
                <hr />
                <p>{each.salary} </p>
                <h2>Job Description</h2>
                <hr />
                <p className="job_description">{each.job_description}</p>
                <button onClick={handleEmail} className="resmue_sending_mail">
                  To apply your resume info@vsdltech.com
                </button>
              </div>
            );
          })}
        </div>
      )} */}

{isModalOpen2 && selectedJob && (
  <div className="application-modal1">
    <div className="application-modal-content1">
      <div className="vsdl-heading-cancel">
        <button className="close-button1" onClick={handleCloseModal2}>
          &times;
        </button>
        <h1>VSDL heading</h1>
      </div>
      <div className="application-modal1-main">
        <div className="application-modal1-heading">
          <h1>VSDL </h1>
        </div>
        <div className="application-modal1-date">
          <h1>Posting Date: </h1>
          <p>{selectedJob.postingDate}</p>
        </div>
      </div>
      <h2>Position</h2>
      <hr />
      <ul>
        <li>{selectedJob.position}</li>
      </ul>
      <h2>Location</h2>
      <hr />
      <p>{selectedJob.location}</p>
      <h2>Duration</h2>
      <hr />
      <p>{selectedJob.duration}</p>

      <h2>Job Type</h2>
      <hr />
      <p>{selectedJob.job_type}</p>
      <h2>Salary</h2>
      <hr />
      <p>{selectedJob.salary}</p>
      <h2>Job Description</h2>
      <hr />
      <p className="job_description">{selectedJob.job_description}</p>
      <button onClick={handleEmail} className="resmue_sending_mail">
        To apply your resume info@vsdltech.com
      </button>
    </div>
  </div>
)}


      {isModalOpen && (
        <div className="application-modal">
          <div className="application-modal-content">
            <button className="close-button" onClick={handleCloseModal}>
              &times;
            </button>
            <h2>Application Form</h2>
            <form>
              <label>Full Name:</label>
              <input type="text" placeholder="Enter your full name" required />
              <label>Email:</label>
              <input type="email" placeholder="Enter your email" required />
              <label>Phone Number:</label>
              <input
                type="tel"
                placeholder="Enter your phone number"
                required
              />
              <label>Resume:</label>
              <input type="file" required />
              <button type="submit">Apply Here</button>
            </form>
          </div>
        </div>
      )}

      <div className="career-section3-title">
        <div>
          <h1>
            Achieve faster responses, better experiences, greater insights.
          </h1>
        </div>
      </div>
      <div className="career-footer">
        <PreFooter />
        <Footer />
      </div>
    </>
  );
}