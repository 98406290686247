// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, push, get } from "firebase/database";
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";


const firebaseConfig = {
  apiKey: "AIzaSyAMXe9XMurwSBfYGt-5Pcu1xmqr6puha8Q",
  authDomain: "vsdl-e277f.firebaseapp.com",
  databaseURL: "https://vsdl-e277f-default-rtdb.firebaseio.com",
  projectId: "vsdl-e277f",
  storageBucket: "vsdl-e277f.appspot.com",
  messagingSenderId: "1019284890247",
  appId: "1:1019284890247:web:71c7b01ef3e1542bc00e22",
  measurementId: "G-30LNCX87YP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app)
const storage = getStorage(app)


export { database, storage, ref, set, push, get, storageRef, uploadBytes, getDownloadURL };