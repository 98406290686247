import React, { useEffect, useState } from "react";
import "./TotalBlogs.css";
import Ellipse1 from "../../Assets/Blogs/Ellipse 3.png";
import Ellipse2 from "../../Assets/Blogs/Ellipse 4.png";
import {
  database,
  storage,
  ref,
  set,
  push,
  storageRef,
  uploadBytes,
  getDownloadURL,
  get,
} from "../firebase/firebase";
// import { dataShare } from "../../App";
import { MdDelete } from "react-icons/md";
import { remove } from "firebase/database";
import { Pagination, Stack } from "@mui/material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IoIosAddCircle } from "react-icons/io";

const TotalBlogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const blogsPerPage = 3;
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    authorName: "",
    image: null,
  });
  const handleApply = () => {
    setIsModalOpen(true);
  };
  //   const handleOpenModal = () => {
  //     setIsModalOpen(true);
  //   };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const fetchBlogs = async () => {
    const blogRef = ref(database, "blogs");
    const snapshot = await get(blogRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      const blogsWithKeys = Object.entries(data).map(([key, value]) => ({
        id: key,
        ...value,
      }));
      setBlogs(blogsWithKeys);
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleDelete = async (id) => {
    const blogRef = ref(database, `blogs/${id}`);
    try {
      await remove(blogRef);
      setBlogs((prevBlogs) => prevBlogs.filter((blog) => blog.id !== id));
      toast.success("Blog deleted successfully!");
      fetchBlogs();
    } catch (error) {
      console.error("Error deleting blog:", error);
      toast.error("Failed to delete the blog. Please try again.");
    }
  };

  const handlePageChange = (event, page) => {
    console.log(page, "page");
    setCurrentPage(page);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    const imageRef = storageRef(storage, `blog_images/${formData.image.name}`);
    await uploadBytes(imageRef, formData.image);
    const imageUrl = await getDownloadURL(imageRef);

    const blogRef = push(ref(database, "blogs"));
    await set(blogRef, {
      title: formData.title,
      content: formData.content,
      authorName: formData.authorName,
      imageUrl,
      date: new Date().toLocaleDateString(),
    });
    setFormData({ title: "", content: "", authorName: "", image: null });
    toast.success("Blog deleted successfully!");
    fetchBlogs();
    setIsModalOpen(false);
  };
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));
  };

  const indexOfLastBlog = currentPage * blogsPerPage;
  const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  return (
    <div className="totalBlogs_container ">
      <div className="totalBlogs_heading">
        <h1>All Blogs</h1>
        <div className="blog_creation_icon">
          <button onClick={handleApply}>Create Blog</button>
        </div>
      </div>
      <div>
        <div className="SingleBlog_Container">
          {currentBlogs.map((Card, index) => (
            <div className="Single_Blog_Container" key={index}>
              <div className="shader_wrap">
                <img
                  src={Card.imageUrl}
                  alt="AI_Img"
                  className="totalBlog_Image"
                />
                <div>
                  <MdDelete
                    className="delete_btn"
                    onClick={() => handleDelete(Card.id)}
                  />
                </div>
              </div>
              <div>
                <p className="totalBlog_Title">{Card.title}</p>
              </div>
              <div>
                <p className="totalBlog_content">{Card.content}</p>
              </div>
              <div className="totalBlogs_Persons_Info">
                <p className="totalBlogs_persons1">
                  <img src={Ellipse1} alt="Elipse"></img>
                  <p>{Card.authorName}</p>
                </p>
                <p className="totalBlogs_persons2">
                  <img src={Ellipse2} alt="Elipse"></img>
                  <p>{Card.date}</p>
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="pagination_container">
        <Stack spacing={2} alignItems="center">
          <Pagination
            count={Math.ceil(blogs.length / blogsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            sx={{
              "& .MuiPaginationItem-page": {
                background: "transparent",
                color: "#000",
                fontWeight: "bold",
              },
              "& .MuiPaginationItem-page.Mui-selected": {
                background: "linear-gradient(180deg, #081acf 0%, #f7714e 100%)",
                color: "#fff",
                fontWeight: "bold",
              },
            }}
          />
        </Stack>
      </div>

      <div>
        {isModalOpen && (
          <div className="application-modal">
            <div className="application-modal-content">
              <button className="close-button" onClick={handleCloseModal}>
                &times;
              </button>
              <h2>Contribute</h2>
              <form onSubmit={handleSubmit}>
                <label>Title:</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  placeholder="Enter your title"
                  required
                />
                <label>Content:</label>
                <textarea
                  style={{ resize: "none" }}
                  name="content"
                  value={formData.content}
                  onChange={handleChange}
                  placeholder="Write your content"
                  required
                ></textarea>
                <label>Author Name:</label>
                <input
                  type="text"
                  name="authorName"
                  value={formData.authorName}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  required
                />
                <label>Image:</label>
                <input
                  type="file"
                  name="image"
                  onChange={handleChange}
                  required
                />
                <button type="submit">Submit</button>
              </form>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TotalBlogs;
