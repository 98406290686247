import React, { useEffect, useState } from "react";
import "./CreationPosition.css";
import { database, ref, set, push, get } from "../firebase/firebase";
import { Link } from "react-router-dom";
import { ImCross } from "react-icons/im";
import { FaBars } from "react-icons/fa";
import VSDL from "../../Assets/Home/VSDL Logo.png";
import { MdDelete, MdEdit } from "react-icons/md";

function CreationPosition() {
  const [showPositions, setShowPositions] = useState(false);
  const [positionData, setPositionData] = useState([]);
  const [active, setactive] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [form, setForm] = useState({
    postingDate: new Date().toISOString().split("T")[0],
    position: "",
    duration: "",
    location: "",
    job_type: "",
    salary: "",
    job_description: "",
    position_status: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [currentPositionId, setCurrentPositionId] = useState(null);

  const fetchPositions = async () => {
    const blogRef = ref(database, "formData");
    const snapshot = await get(blogRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      setPositionData(
        Object.entries(data).map(([id, positionDetails]) => ({
          id,
          ...positionDetails,
        }))
      );
    }
  };

  const handleInputs = (e) => {
    const { name, value } = e.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handlePositions = () => {
    setShowPositions(true);
    fetchPositions();
  };

  const handleClose = () => {
    setShowPositions(false);
    setEditMode(false); // Reset edit mode when closing
    setForm({
      postingDate: new Date().toISOString().split("T")[0],
      position: "",
      duration: "",
      location: "",
      job_type: "",
      salary: "",
      job_description: "",
      position_status: "",
    });
  };

  const handleForm = async (e) => {
    e.preventDefault();
    if (editMode) {
      const positionRef = ref(database, `formData/${currentPositionId}`);
      await set(positionRef, form);
    } else {
      const blogRef = push(ref(database, "formData"));
      await set(blogRef, form);
    }
    handleClose(); // Reset after saving
    fetchPositions();
  };

  const handleDelete = async (id) => {
    const deleteRef = ref(database, `formData/${id}`);

    await set(deleteRef, null);

    setPositionData((prev) => prev.filter((position) => position.id !== id));
  };

  const handleEdit = (position) => {
    setForm(position);
    setEditMode(true);
    setCurrentPositionId(position.id);
    setShowPositions(false)
  };

  const handleClickNav = () => {
    setactive(!active);
  };

  useEffect(() => {
    const handleScroll = () => {
      window.scrollY == 0 ? setIsScrolled(false) : setIsScrolled(true);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <div className="backTransition">
      <div className="MH_Nav_Main">
        <div className={isScrolled ? "Nav_Bar" : "Nav_Bar_hide"}>
          <div className="MH_Nav_Bar">
            <Link style={{ textDecoration: "none" }} to="/">
              <img src={VSDL} alt="Logo" />
            </Link>
            {active ? (
              <div>
                <ImCross className="CrossMark" onClick={handleClickNav} />
              </div>
            ) : (
              <div>
                <FaBars className="CuroselIcon" onClick={handleClickNav} />
              </div>
            )}
          </div>
          <ul className={`Nav_Links ${active ? "shownavLinks" : "noNavLink"}`}>
            <li>
              <Link
                to="/"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Home
              </Link>
            </li>
            <li className="who-we-are-home">
              <Link
                to="/WhoWeAre"
                style={{
                  textDecoration: "none",
                  color: "#DFE3FB",
                }}
                onClick={handleClickNav}
              >
                Who We Are
              </Link>
            </li>
            <li className="what_we_do_home">
              <Link
                to="/Services"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                What We Do
              </Link>
            </li>
            <li>
              <Link
                to="/Blogs"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Blog
              </Link>
            </li>
            <li>
              <Link
                to="/Careers"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Careers
              </Link>
            </li>
            <li>
              <Link
                to="/Contact"
                style={{ textDecoration: "none", color: "#DFE3FB" }}
                onClick={handleClickNav}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* <div className="round-curcle"> */}
      {/* <h1 className="vsdl">VSDL</h1> */}
      {!showPositions ? (
        <>
          <div className="card">
          {/* <div className="outer_top_circle"></div> */}
            <form onSubmit={handleForm}>
              <h1 className="heading_cp">{editMode ? "Edit Position" : "Create Position"}</h1>
              <div className="CP_inputs">
                <div className="input_fields">
                  <input
                    type="date"
                    name="postingDate"
                    placeholder="Enter Date"
                    required
                    value={form.postingDate}
                    onChange={handleInputs}
                  />
                  <input
                    type="text"
                    name="position"
                    className="capitalize"
                    placeholder="Enter Job Title"
                    required
                    value={form.position}
                    onChange={handleInputs}
                  />
                </div>
                <div className="input_fields">
                  <input
                    type="text"
                    name="duration"
                    placeholder="Duration"
                    required
                    value={form.duration}
                    onChange={handleInputs}
                  />
                  <input
                    type="text"
                    name="location"
                    className="capitalize"
                    placeholder="Enter Location"
                    value={form.location}
                    required
                    onChange={handleInputs}
                  />
                </div>
                <div className="input_fields">
                  <input
                    type="text"
                    name="job_type"
                    placeholder="Enter Job Type"
                    value={form.job_type}
                    onChange={handleInputs}
                    // rows="4"
                    // className="description-field"
                    required
                  />
                  <input
                    name="salary"
                    placeholder="Salary"
                    value={form.salary}
                    onChange={handleInputs}
                    // rows="4"
                    // className="description-field"
                    required
                  />
                </div>
                <div className="input_fields1">
                  <textarea
                    name="job_description"
                    placeholder="Enter Job Description"
                    value={form.job_description}
                    onChange={handleInputs}
                    // rows="4"
                    className="description-field"
                    required
                  ></textarea>
                </div>
              </div>
              <div className="two-btn">
                <div className="position_status">
                  <label>Position Status:</label>
                  <select
                    name="position_status"
                    value={form.position_status}
                    onChange={handleInputs}
                    required
                  >
                    <option value="">Select Option</option>
                    <option value="Open">Open</option>
                    <option value="Close">Close</option>
                  </select>
                </div>
                <button
                  type="button"
                  onClick={handlePositions}
                  className="position-save"
                >
                  View Positions
                </button>
                <button type="submit" className="position-save">
                  {editMode ? "Update" : "Save"}
                </button>
              </div>
            </form>
          {/* <div className="bottom_down_circle"></div> */}
          </div>
        </>
      ) : (
        <div className="card1">
          {positionData.map((position) => (
            <div key={position.id} className="dleteFunctionality">
              <ul>
                <li>{position.position}</li>
                <li>{position.location}</li>
              </ul>
              <div className="function_btns">
                <div className="delete-btn ">
                  <MdDelete onClick={() => handleDelete(position.id)} />
                </div>
                <div className="cp_edit_btn ">
                  <MdEdit onClick={() => handleEdit(position)} />
                </div>
              </div>
            </div>
          ))}
          <button onClick={handleClose} className="position-save closeee">
            Close
          </button>
        </div>
      )}
    </div>
    // </div>
  );
}

export default CreationPosition;
